/* eslint-disable import/order */
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { Button, Col, Form, Input, Modal, Row, Table, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { eTypeVatTuMayMoc, PhieuDeNghiMuaHangDTO } from '@/common/define';
import { ChiTietDeNghiMuaHangDTO } from '@/services/AccountingInvoiceService';
import { accountingInvoiceActions, getProducts } from '@/store/accountingInvoice';
import { getgetUserIIS } from '@/store/app';
import ImportGoods from '../ImportGoods';
import { ProposalData } from '../ProposalHistory';
import styles from './MachineryMaterialsConfirm.module.css';

const { Title } = Typography;

interface MachineryMaterialsFormProps {
  proposal: ProposalData;
  type: eTypeVatTuMayMoc;
  capDuyet: number;
  handleClose: any;
}

const MachineryMaterialsConfirm: React.FC<MachineryMaterialsFormProps> = ({ proposal, capDuyet, handleClose }) => {
  const userIIS = useAppSelector(getgetUserIIS());
  const data = proposal.chiTietDeNghiMuaHang;
  const [chiTietHangHoa, setChiTietHangHoa] = useState<ChiTietDeNghiMuaHangDTO[]>([]);
  const productList = useAppSelector(getProducts());
  const dispatch = useAppDispatch();
  const { t } = useTranslation('material');
  const [importModal, setImportModal] = useState<boolean>(false);
  const value = (proposal.capDuyetHienTai ?? 0) + 1;
  const handleImportButtonClick = () => {
    setImportModal(true);
  };

  const handleModalOk = () => {
    setImportModal(false);
  };

  const handleModalCancel = () => {
    setImportModal(false);
  };
  const SplitBySupplier = () => {
    dispatch(accountingInvoiceActions.splitDeNghiMuaHangTheoNhaCungCap({
      params: {
        guid: proposal.guid,
      }
    }))
    handleClose();
  };
  const GetTheLowestSupplierPrice = () => { };
  const GetTheNearestSupplierPrice = () => { };
  useEffect(() => {
    if (userIIS && userIIS.length > 0) {
        console.log(value, userIIS[0].capDuyetChi, 'data');
    }
    const updatedData = data.map((item: any) => {
      const product = productList.find((p) => p.ma_vt === item.ma_vt);
      return {
        ...item,
        name: product?.ten_vt || '', // Default to empty string if not found
        unit: product?.dvt || '', // Default to empty string if not found
        key: item.ma_vt,
      };
    });
    setChiTietHangHoa(updatedData);
    console.log('updated');
  }, [data, productList]);

  const renderEditableCell = (text: any, record: any, field: string) => {
    return (
      <Input
        value={text}
        onChange={(e) => {
          const newValue = e.target.value;
          setChiTietHangHoa((prevData) =>
            prevData.map((item) => {
              if (item.key === record.key) {
                return { ...item, [field]: newValue }; // Update only the matching row
              }
              return item;
            })
          );
        }}
      />
    );
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('vi-VN'); // Use 'vi-VN' for Vietnamese locale
  };
  const columns: ColumnType<(typeof chiTietHangHoa)[0]>[] = [
    {
      title: <span>{t('Material code')}</span>,
      dataIndex: 'ma_vt',
      key: 'ma_vt',
      width: 150,
      align: 'center',
    },
    {
      title: <span>{t('Material name')}</span>,
      dataIndex: 'name',
      key: 'name',
      width: 120,
      align: 'center',
    },
    {
      title: <span>{t('Unit')}</span>,
      dataIndex: 'unit',
      key: 'unit',
      width: 93,
      align: 'center',
    },
    {
      title: <span>{t('This time proposal')}</span>,
      dataIndex: 'so_luong_yeu_cau',
      key: 'so_luong_yeu_cau',
      width: 160,
      align: 'center',
    },
    {
      title: <span>{t('Approved by')}</span>,
      dataIndex: 'NguoiDuyet1',
      key: 'NguoiDuyet1',
      width: 136,
      align: 'center',
    },
    {
      title: <span>{t('Approved quantity')}</span>,
      dataIndex: 'so_luong_nhap1',
      key: 'so_luong_nhap1',
      width: 197,
      render: (text, record) => renderEditableCell(text, record, 'so_luong_nhap1'),
      align: 'center',
    },
    {
      title: <span>{t('Approved price')}</span>,
      dataIndex: 'gia',
      key: 'gia',
      width: 157,
      render: (text, record) => renderEditableCell(text, record, 'gia'), // Make this column editable when capDuyet = 0
      align: 'center',
    },
    {
      title: <span>{t('Into money')}</span>,
      dataIndex: 'tien',
      key: 'tien',
      width: 124,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Price')} 1</span>,
      dataIndex: 'gia1',
      key: 'gia1',
      width: 130,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Supplier')} 1</span>,
      dataIndex: 'nhaCungCap1',
      key: 'nhaCungCap1',
      width: 300,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    }, {
      title: <span className={styles.tableHeader}>{t('Price')} 2</span>,
      dataIndex: 'gia2',
      key: 'gia2',
      width: 130,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Supplier')} 2</span>,
      dataIndex: 'nhaCungCap2',
      key: 'nhaCungCap2',
      width: 300,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    }, {
      title: <span className={styles.tableHeader}>{t('Price')} 3</span>,
      dataIndex: 'gia3',
      key: 'gia3',
      width: 130,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Supplier')} 3</span>,
      dataIndex: 'nhaCungCap3',
      key: 'nhaCungCap3',
      width: 300,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    },
  ];
  const confirmProposal = () => {
    // Destructure to remove color from proposal       
    const { color, ...proposalWithoutColor } = proposal;

    // Remove 'name' and 'unit' properties from chiTietHangHoa
    const updatedChiTietHangHoa = chiTietHangHoa.map(item => {
      const { name, unit, key, ...rest } = item; // Destructure to remove name
      return rest; // Return the rest of the properties
    });

    // Initialize a new variable with the proposal object 
    const updatedProposal = {
      ...proposalWithoutColor,
      chiTietHangHoa: updatedChiTietHangHoa,
      chiTietDeNghiMuaHang: updatedChiTietHangHoa,
      hoaDonVAT: [],
      list_of_extensions: []
    };

    // Assign the user based on the value of capDuyet
    if (userIIS && userIIS.length > 0) {
      switch (capDuyet) {
        case 0:
          updatedProposal.nguoiDuyet1 = userIIS[0].un; // Assign user to nguoiDuyet1
          break;
        case 1:
          updatedProposal.nguoiDuyet2 = userIIS[0].un; // Assign user to nguoiDuyet2
          break;
        case 2:
          updatedProposal.nguoiDuyet3 = userIIS[0].un; // Assign user to nguoiDuyet3
          break;
        case 3:
          updatedProposal.nguoiDuyet4 = userIIS[0].un; // Assign user to nguoiDuyet4
          break;
        case 4:
          updatedProposal.nguoiDuyet5 = userIIS[0].un; // Assign user to nguoiDuyet5
          break;
        default:
          console.warn('Unknown capDuyet level');
          break;
      }
    } else {
      console.warn('userIIS is undefined or empty');
    }

    console.log('Updated Proposal:', updatedProposal);
    dispatch(accountingInvoiceActions.ConfirmProposalForm({
      data: updatedProposal as unknown as PhieuDeNghiMuaHangDTO,
      params: {}
    }));
    handleClose();
  };
  return (
    <div>
      <div>
        <Row>
          <Col>
            <Title level={3}>Duyệt đề xuất vật tư</Title>
          </Col>
        </Row>
        <Form
          initialValues={{
            code: proposal.id,
            category: proposal.ma_ct,
          }}
          className={styles.formLayout}
        >
          <Row gutter={16} style={{ width: '120%' }}>
            <Col span={6} className={styles.formItemCol}>
              <Form.Item label={`Cấp duyệt hiện tại`} name="code" className={styles.formItem}>
                <div>{proposal.capDuyetHienTai === 5 ? "Nhập kho" : proposal.capDuyetHienTai}</div>
              </Form.Item> 
            </Col>
            <Col span={6} className={styles.formItemCol}>
              <Form.Item label={t('Date of creation of ticket')} className={styles.formItem}>
                <div>{formatDate(proposal.createDate)}</div>
              </Form.Item>
            </Col>
            <Col span={6} className={styles.formItemCol}  >
              <Form.Item label={t('Date of request for import')} className={styles.formItem}>
                <div>{formatDate(proposal.ngay_ct)}</div>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Table
          dataSource={chiTietHangHoa}
          columns={columns}
          pagination={false}
          scroll={{ x: 'max-content', y: '50vh' }}
        />
      </div>

      <div id="div2" className={styles.container}>
        {/* Show the "Import to warehouse" button only if capDuyet >= 3 */}

        {(proposal.capDuyetHienTai ?? 0) > proposal.capDuyet ? (
          <div>
            <Button type="primary" onClick={handleImportButtonClick}>
              {t('Import to warehouse')}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                SplitBySupplier();
              }}
            >
              {t('Split by supplier')}
            </Button>
          </div>
        ) : (
          <div></div>
        )}
        <div>
          <Button
            type="primary"

            style={{ marginRight: 10 }}
            disabled
            onClick={() => {
              GetTheLowestSupplierPrice();
            }}
          >
            {t('Get the lowest supplier price')}
          </Button>
          <Button
            type="primary"

            disabled
            style={{ marginRight: 10 }}
            onClick={() => {
              GetTheNearestSupplierPrice();
            }}
          >
            {t('Get the nearest supplier price')}
          </Button>
        </div>
        <div className={styles.buttonNhapkho}>
          <Button
            type="primary"
            disabled={userIIS && userIIS[0] ? userIIS[0].capDuyetChi !== value : true}
            onClick={confirmProposal}
          >
            Duyệt đề xuất
          </Button>
        </div>
      </div>
      <Modal open={importModal} onOk={handleModalOk} onCancel={handleModalCancel} bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }} footer={null} width={1250} style={{ top: 20 }}>
        <div style={{ height: '100%' }}>
          <ImportGoods proposal={proposal} />
        </div>
      </Modal>
    </div>
  );

};

export default MachineryMaterialsConfirm;

/* eslint-disable import/order */
/* eslint-disable import/order */
import React, { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import type { TabsProps } from 'antd';
import { Button, DatePicker, Space, Tabs } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import Style from './TabHeader.module.less';
import { getLoading } from '@/store/loading';
import { useAppSelector } from '@/store/hooks';
import { accountingInvoice } from '@/common/define';

const { RangePicker } = DatePicker;

interface TabHeaderProps {
  tabs: TabsProps['items'];
  onAddProposal?: () => void;
  onAddMorearise?: () => void;
  onDownload?: () => void;
  onSelectDate?: (dates: [Dayjs | null, Dayjs | null] | null) => void;
}

const TabHeader: React.FC<TabHeaderProps> = ({ tabs, onAddProposal, onDownload, onSelectDate, onAddMorearise }) => {
  const { t } = useTranslation('tabheader');
  const [activeTab, setActiveTab] = useState<string>('1');
  const today = dayjs();
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([today, today]);
  const isLoading = useAppSelector(getLoading(accountingInvoice.GetTonKho));
  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };
  const handleDateChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    setDateRange(dates as [Dayjs, Dayjs]);
    if (onSelectDate) {
      onSelectDate(dates);
    }
  };
  
  return (
    <div>
      <div className={Style.tabContainer}>
        <Tabs
          defaultActiveKey="1"
          onChange={handleTabChange}
          tabBarExtraContent={
            <div className={Style.tabActions}>
              
              {activeTab === '2' && (
                <Space direction="vertical" size={12} className={Style.datePicker}>
                  {/* Use RangePicker for selecting a date range */}
                  <RangePicker onChange={handleDateChange} value={dateRange} />
                </Space>
              )}
              {activeTab === '3' && (
                <Button
                  icon={<PlusOutlined />}
                  type="primary"
                  style={{ marginRight: 8, borderRadius: '20px' }}
                  onClick={onAddMorearise}
                >
                  {t('More arise')}
                </Button>
              )}
              {activeTab !== '3' && (
                <Button
                  icon={<PlusOutlined />}
                  type="primary"
                  style={{ marginRight: 8, borderRadius: '20px' }}
                  onClick={onAddProposal}
                  disabled={isLoading}
                >
                  {t('Add Proposal')}
                </Button>
              )}
              {/* <Button
                icon={<DownloadOutlined />}
                style={{ marginRight: 8 }}
                onClick={onDownload}
              />
              <Button icon={<EllipsisOutlined />} /> */}
            </div>
          }
          items={tabs}
        />
      </div>
    </div>
  );
};

export default TabHeader;

import { useEffect, useState } from 'react';

import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Button, Empty, Modal, PaginationProps, Select, Table, TableProps, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './Bidding.module.less';
import { BidHeader } from './BidHeader';
import { issuesColumns } from './columns/IssuseColumn';
import Menucontext from './components/Menucontext';
import { CreateUpdateIssue } from './CreateUpdateIssue';
import { expandIconCustom } from '../Components/expandIcon';
import {
  CreateUpdateIssueModalName,
  GettingIssueList,
  RemovingIssue,
  SavingIssue,
  UpdateStatusIssue,
  defaultPagingParams,
  largePagingParams,
  IInforParenComponent,
  sMilestone,
  GettingIssueByVersionList,
  genIssue,
  eTrackerCode,
} from '@/common/define';
import { Loading } from '@/components';
import { Gantt } from '@/components/Gantt/Gantt';
import { useWindowSize } from '@/hooks';
import {  BiddingDTO, codeStatus, IssueService, IssuesResponse, Status, StatusHelperControl, StatusLabel } from '@/services/IssueService';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getIssueQueryParams, getIssueStatusList, getIssues, getIssuesView, issueActions, getIssueByVersion, getTagsVersion, getTracker, queryParamsByTagVersion } from '@/store/issue';
import { getLoading, startLoading } from '@/store/loading';
import { getModalVisible, showModal } from '@/store/modal';
import { getSelectedProject, projectActions, folderisCreated } from '@/store/project';
import Utils from '@/utils';

type TableRowSelection<T> = TableProps<T>['rowSelection'];

export const Bidding = () => {
  const { Option } = Select;
  const { t } = useTranslation('bidding');

  const windowSize = useWindowSize();

  const dispatch = useAppDispatch();
  const [biddingIssues, setFullDataSet] =  useState<BiddingDTO[]>([]);
  const selectedProject = useAppSelector(getSelectedProject());
  const view = useAppSelector(getIssuesView());
  const issues = useAppSelector(getIssues());
  const biddingTasksIssues = useAppSelector(getIssueByVersion());
  const issueModal = useAppSelector(getModalVisible(CreateUpdateIssueModalName));
  const params = useAppSelector(getIssueQueryParams());
  const isLoading = useAppSelector(getLoading(GettingIssueByVersionList));
  const isLoadingUpdateStatus = useAppSelector(getLoading(UpdateStatusIssue));
  const isRemoving = useAppSelector(getLoading(RemovingIssue));
  const isSaving = useAppSelector(getLoading(SavingIssue));
  const issueStatusList = useAppSelector(getIssueStatusList());
  const tags = useAppSelector(getTagsVersion());
  const [dataInita, setDataInit] = useState<BiddingDTO[]>([]);
  const [checkStrictly,] = useState(true);
  const [popup, setPopup] = useState<PopupState>({ visible: false, x: 0, y: 0 , record: []});
  const [countRows, setCountRows]= useState<BiddingDTO[]>([])
  const [filteredData, setFilteredData] = useState<BiddingDTO[] | null>(null);
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const fullDataSet: BiddingDTO[] = [...dataInita]; 
  const [loading, setLoading] = useState<boolean>(true);
  const isLoadingGenIssue = useAppSelector(getLoading(genIssue));
  const trackers = useAppSelector(getTracker());
  const paramsVersion = useAppSelector(queryParamsByTagVersion())
  const folderisCreate = useAppSelector(folderisCreated());
  const loadingFolder = useAppSelector(getLoading('getFolderRootIds'));

  const getTrackerID = () => {
    let trackerId = 20;
    if (trackers && trackers.length) {
      const tracker = trackers?.find(t => t.code === eTrackerCode.CongViecHangTuan);
      if (tracker && tracker.id) {
        trackerId = tracker.id;
      }
    }
    return trackerId;
  }
  const ascending = false;
  const infoParentComponent: IInforParenComponent = {
    tagVersionId: Utils.getMileStoneId(sMilestone.Bid, tags), 
    typeUpdate: sMilestone.Bid, 
    pageSize: 20,
    ascending
  }
  type PopupState = {
    visible: boolean;
    x: number;
    y: number;
    record: BiddingDTO[];
  };

  useEffect(()=> {
    if (folderisCreate && selectedProject) {
      dispatch(startLoading({ key: 'getFolderRootIds' }));
      dispatch(projectActions.getFolderRootId({projectId: selectedProject.id, isGetId: false}))
    }
  },[folderisCreate])


  useEffect(()=>{
    if (!biddingTasksIssues) {
      setLoading(true);
    }
    if (biddingTasksIssues && biddingTasksIssues.results && biddingTasksIssues.results.length > 0) {
      const sortDataFllowDayPlanStart = Utils.sortIssueByPlanStartDay(biddingTasksIssues);
      const newData = mappingData(sortDataFllowDayPlanStart);
      setDataInit(newData);
      setFullDataSet(newData);
      setLoading(false)
    }
  },[paramsVersion, biddingTasksIssues?.results.length, isRemoving, isSaving, isLoading, isLoadingGenIssue])

  const mappingData = (biddingTasksIssues: any) => {
    const results: BiddingDTO[] = [];
    biddingTasksIssues?.forEach((item: any) => {
      let status = Utils.getStatus(item.status);
      if (+item.progress === 100) {
        status = Utils.getStatus(sMilestone.Complete);
      }
      const datamap : BiddingDTO = {
        ...item,
        status: Utils.getStatus(item.status),
        children: [],
      }
      results.push(datamap);
    })
    const dataTotree = mapDataToTree(results);
    return dataTotree;
  }

  const mapDataToTree = (data: any) => {
    const idMapping = data.reduce((acc: any, el: any, i: any) => {
        acc[el.id] = i;
        return acc;
    }, {});
    const root: any = [];
    data.forEach((el: any) => {
        if (el.parentId === null || !idMapping.hasOwnProperty(el.parentId) || el.parentId === el.id) {
            root.push(el);
            return;
        }
        const parentEl = data[idMapping[el.parentId]];
        if (parentEl) {
          parentEl.children = [...(parentEl.children || []), el];
        } else {
          root.push(el)
        }
    });
    return root;
};
  



  useEffect(() => {
    if (!selectedProject) {
      dispatch(issueActions.setIssues(undefined));
      dispatch(projectActions.setProjectMembers(undefined));
      return;
    }
    let trackerId = getTrackerID();
    dispatch(
      issueActions.getIssuesByMilestoneRequest({
        projectId: selectedProject.id,
        params: {
          ...params,
          page: 1,
          search: undefined,
          tagVersionId: Utils.getMileStoneId(sMilestone.Bid, tags),
          pageSize: 20,
          ascending,
          trackerId,
        },
      }),
    );
    dispatch(issueActions.getMembersToGroup({groupId: 1}))
    // eslint-disable-next-line
  }, [selectedProject, tags]);

  let trackerId = getTrackerID();
  useEffect(() => {
    setLoading(true);
    dispatch(
      issueActions.getIssuesByMilestoneRequest({
        projectId: selectedProject?.id,
        params: {
          ...params,
          page: 1,
          search: undefined,
          tagVersionId: Utils.getMileStoneId(sMilestone.Bid, tags),
          pageSize: 20,
          ascending,
          trackerId,
        },
      }),
    );
    if (!isSaving || !isLoadingUpdateStatus || !isRemoving) {
      setLoading(false)
    }
  },[isRemoving, isSaving, isLoadingUpdateStatus, view])

  const statusOptionsTable = StatusHelperControl.statusOptionFilter;
  
  const handleStatusChange = (value : any, record: any, isChangeOnMenuContext: boolean) => {
    if (isChangeOnMenuContext) {
      if (countRows && countRows.length > 0) {
        const listIdIssue: any[] = [];
        countRows.filter((i:any) => i !== undefined && i.status === Status.Pending).map((r: any) => listIdIssue.push(r.id));
        dispatch(issueActions.updateMultiStatusIssue({id: listIdIssue, projectId: record.projectId, code: codeStatus.ApprovedSeries}))
      } else {
        if (+record.status !== Status.Pending) return;
        dispatch(issueActions.updateStatusIssue({id: record.id, projectId: record.projectId, code: codeStatus.Approved}))
      }
    } else {
      const code = Utils.convertStatusApi(value);
      dispatch(issueActions.updateStatusIssue({id: record.id, projectId: record.projectId, code: code}))

    }
  };
  

  const handleFilterValueTable = (value: any, data: any) => {
       let filteredData: BiddingDTO[] = [];
       if (+data.key === Status.Approved || +data.key === Status.Done || +data.key === Status.Pending || +data.key === Status.Processing) {
        filteredData = filterTree(fullDataSet, +data.key);
        setIsFiltered(true);
        setFilteredData(filteredData);
    } else {
        filteredData = [...fullDataSet];  // Nếu không khớp giá trị nào, trả về toàn bộ dữ liệu
        setIsFiltered(false);
    }
  }

  const filterTree= (nodes: BiddingDTO[], status: number) : BiddingDTO[] => {
    return nodes.map((item: BiddingDTO) => {
        if (item.children && item.children.length > 0) {
            const filteredData = filterTree(item.children, status);
            if (filteredData.length > 0 || item.status === status) {
              return {
                ...item,
                children: filteredData
              }
            } 
          } else if (+item.status === status) {
            return { ...item, children: []}
          }
        return null;
    }).filter(node => node !== null) as BiddingDTO[]
  }

  const editIssue = (issue: any, isEdit: boolean) => {
      if(!isEdit) {
        dispatch(issueActions.setEditIssuePublics(false))
        dispatch(issueActions.setSelectedIssue(issue));
        dispatch(showModal({ key: CreateUpdateIssueModalName }));
      } else {
        dispatch(issueActions.setEditIssuePublics(true))
        dispatch(issueActions.getFileAttachmenForIssue({issueId: issue.id}));
        dispatch(issueActions.setSelectedIssue(issue));
        dispatch(showModal({ key: CreateUpdateIssueModalName }));
      }
  };


  const confirmRemoveIssue = (issue: IssuesResponse, listRecord: any) => {
    let issueId: any[] = []
    if (listRecord) {
      issueId = listRecord.length > 0 && countRows.length > 0 
      ? listRecord.filter((i: any)=> i !== undefined).map((item: any) => item.id) 
      : [issue.id];
    } else {
      issueId = [issue]
    }
    Modal.confirm({
      title: t('Notification'),
      content: (

        <div
          dangerouslySetInnerHTML={{
            __html: t(`${countRows.length === 0 ?  t('Confirm remove') : t('Confirm')}`, {
              name: `<strong>"${countRows.length > 0 ? '': issue.id}"</strong>`,
            }),
          }}
        />
      ),
      closable: true,
      maskClosable: true,
      onOk: close => {
        handleRemoveIssue(issueId, listRecord);
        close();
      },
    });
  };

  const handleRemoveIssue = (issueId: any[], listRecord: any) => {
    if((selectedProject && countRows.length === 0) || !listRecord) {
      dispatch(issueActions.removeIssueRequest({ issueId: !listRecord ? issueId[0].id : issueId[0], projectId: selectedProject?.id }))
    } else if (listRecord) {
      dispatch(issueActions.deleteMultiIssue({listIdIssue: issueId, projectId: selectedProject?.id}))
      countRecord.push([]);
    } 
  };

  const handleIssueTableChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const trackerId = getTrackerID();
    const search = { ...params, page: current, pageSize: 20, tagVersionId: Utils.getMileStoneId(sMilestone.Bid, tags) ,trackerId, ascending};
    if (selectedProject) {
      dispatch(
        issueActions.getIssuesByMilestoneRequest({
          projectId: selectedProject.id,
          params: search,
        })
      );
    }
  };
  const showTotal: PaginationProps['showTotal'] = (total, range) =>
    t('Paging total', { range1: range[0], range2: range[1], total });

  const rowSelection: TableRowSelection<BiddingDTO> = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setPopup((prevState) => ({ ...prevState, visible: false }));
      setCountRows(selectedRows);
      // console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setCountRows(selectedRows);
      // console.log(selected, selectedRows, changeRows);
    },
  };
  const countRecord: any[] = []
  const onRow = (record: any) => ({
    onContextMenu: (event : any) => {
      event.preventDefault();
      if (countRows.length > 0) {
        countRecord.push(...countRows);
      } else {
        countRecord.push(record);
      }
      if (!popup.visible) {
        const onClickOutside = () => {
          setPopup((prevState) => ({ ...prevState, record: [], visible: false }));
          document.removeEventListener('click', onClickOutside);
        };
        document.addEventListener('click', onClickOutside);
      }
      setPopup({
        record: countRecord,
        visible: true,
        x: event.clientX,
        y: event.clientY,
      });
    },
  });
  const dataSource = isFiltered ? filteredData : dataInita;
  return (
    <>
      {issueModal && <CreateUpdateIssue tagVersionId={Utils.getMileStoneId(sMilestone.Bid, tags)} />}
      <BidHeader />
      <div className={styles.wrapperbiding}>
        {biddingTasksIssues && biddingTasksIssues.results && biddingTasksIssues.results.length === 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 150px)',
              backgroundColor: 'white',
              margin: 10,
            }}
          >
            <Empty
              description={
                <>
                  <Typography.Title level={4}>{t('No data found based on filtering criteria')}</Typography.Title>
                  {/* <Typography.Text>{t('Try reselecting the filtering criteria to find your data')}</Typography.Text> */}
                </>
              }
            />
          </div>
        )}
        {view === 'List' && biddingTasksIssues && biddingTasksIssues.results && biddingTasksIssues.results.length > 0 && (
          <>  
            {loading ? <Loading/> :
            <div style={{ padding: 10 }}>
              <div style={{width:"100%", background:'white', height: '40px', padding:"10px"}}>
            <Select
              className={styles.customSelect}
              style={{height:'25px', width: '150px'}}
              defaultValue={statusOptionsTable[0].label}
              onChange={(value, data) => handleFilterValueTable(value, data)}
            >
              {statusOptionsTable.map(option => (
                <Option 
                key={option.value} 
                value={option.label}
                >
                    {option.label}
                </Option>
              ))}
            </Select>
              </div>
              <Table
                className='biddingCustom'
                rowKey={record => record.id}
                dataSource={dataSource!}
                columns={issuesColumns({t, handleStatusChange, editIssue, confirmRemoveIssue})}
                style={{ width: '100%' }}
                size="small"
                scroll={{ x: 1000, y: windowSize[1] - 200 }}
                rowSelection={{ ...rowSelection, checkStrictly }}
                pagination={false}
                // pagination={{
                //   current: paramsVersion?.page || defaultPagingParams.page,
                //   pageSize: paramsVersion?.pageSize > 9999 ? defaultPagingParams.pageSize : paramsVersion?.pageSize,
                //   total: biddingTasksIssues?.queryCount || 0,
                //   responsive: true,
                //   showTotal,
                //   showSizeChanger: true,
                // }}
                loading={isLoading || isRemoving || loading || isLoadingUpdateStatus || isLoadingGenIssue}
                onChange={handleIssueTableChange}
                expandable={{
                  expandIcon: ({ expanded, onExpand, record }) => expandIconCustom({expanded, onExpand, record, editIssue}),
                  expandIconColumnIndex: 3,
                }}
                onRow={onRow}
              />
                <Menucontext 
                {...popup} 
                countRows={countRows.length} 
                confirmRemoveIssue={confirmRemoveIssue}
                editIssue={editIssue}
                t={t}
                handleStatusChange={handleStatusChange}
                />
            </div>
            }
          </>
        )}
        {view === 'Gantt'  && biddingTasksIssues && biddingTasksIssues.results && biddingTasksIssues.results.length > 0 && (
        <Gantt infoParentComponent={infoParentComponent}/>
        )}
      </div>
    </>
  );
};

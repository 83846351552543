/* eslint-disable import/order */
import { useEffect, useState } from 'react';

import { Modal, TabsProps } from 'antd';
import dayjs from 'dayjs';

import { eTypeVatTuMayMoc, FormatDateAPI } from '@/common/define';
import TabHeader from '@/components/Layout/TabHeader/TabHeader';
import { accountingInvoiceActions, getMayMoc, getProducts, getWareHouses } from '@/store/accountingInvoice';
import { getCurrentCompany } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getTracker, issueActions } from '@/store/issue';
import { getSelectedProject } from '@/store/project';
import AdditionalCost from './components/AdditionalCost';
import MachineryMaterialsList from './components/MachineryMaterialsList';
import NewMachineryMaterialList from './components/NewMachineryMaterialList';
import ProposalHistory from './components/ProposalHistory';
import Utils from '@/utils';
interface MachineryMaterialsProps {
  type: eTypeVatTuMayMoc;
}
export const MachineryMaterials = (props: MachineryMaterialsProps) => {
  const { type } = props;
  const company = useAppSelector(getCurrentCompany());
  const selectedProject = useAppSelector(getSelectedProject());
  const producsts = useAppSelector(getProducts());
  const machineries = useAppSelector(getMayMoc());
  const wareHouses = useAppSelector(getWareHouses());
  const trackers = useAppSelector(getTracker());

  const dispatch = useAppDispatch();

  useEffect(() => {  
    // console.log(type);
    // dispatch(accountingInvoiceActions.GetDanhSachDuyetMuaHang({ params: {
    //   madvcs: 'THUCHIEN',
    //   ngay_de_nghi: '2024/09/16'
    // } }));
    dispatch(accountingInvoiceActions.GetProducts({ params: {} }));
    dispatch(accountingInvoiceActions.GetDanhSachThietBi({ params: {} }));
    dispatch(accountingInvoiceActions.GetWareHouse({ params: {} }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Function to handle dispatching GetTonKho based on selectedProject
    const dispatchTonKho = () => {
      if (selectedProject && Array.isArray(selectedProject.maKho)) {
        // Dispatch with danhSachMakho from selectedProject
        dispatch(
          accountingInvoiceActions.GetTonKho({
            data: {
              madvcs: 'THUCHIEN',
              danhSachMaHang: [],
              ngay_kiem_tra: dayjs().format(FormatDateAPI),
              danhSachMakho: [...selectedProject.maKho], // Ensure maKho is an array
            },
            params: {},
          })
        );
      } else {
        // Fallback to default danhSachMakho
        if (selectedProject) return;
        dispatch(
          accountingInvoiceActions.GetTonKho({
            data: {
              madvcs: 'THUCHIEN',
              danhSachMaHang: [],
              ngay_kiem_tra: dayjs().format(FormatDateAPI),
              danhSachMakho: ['TONGTEST', 'TONGTEST CCDC'], // Default values
            },
            params: {},
          })
        );
      }
    };
  
    // Only dispatch if selectedProject or company changes
    dispatchTonKho();
  }, [selectedProject, company]);

  useEffect(() => {
    if (company) {
      // dispatch(issueActions.getTagByCompanyIdRequest({ companyId: company.id }));
      dispatch(issueActions.getCategoryByCompanyIdRequest({ companyId : company.id }));
      dispatch(issueActions.getTrackerByCompany({ id: company.id }));
    }
  }, [company, dispatch]);
  useEffect(() => {
    // console.log('producsts ', producsts);
    // console.log('machineries ', machineries);
  }, [producsts, machineries]);
  useEffect(() => {
    // console.log('wareHouses ', wareHouses);
  }, [wareHouses]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  let tabs: TabsProps['items'] = [];
  
  switch (type) {
    case eTypeVatTuMayMoc.VatTuChinh:
      tabs = [
        {
          key: '1',
          label: 'Danh sách vật tư chính',
          children: <MachineryMaterialsList type={type} />,
        },
        {
          key: '2',
          label: 'Lịch sử đề xuất cấp vật tư chính',
          children: <ProposalHistory type={type} />,
        },
      ];
      break;
    case eTypeVatTuMayMoc.VatTuPhu:
      tabs = [
        {
          key: '1',
          label: 'Danh sách vật tư phụ',
          children: <MachineryMaterialsList type={type} />,
        },
        {
          key: '2',
          label: 'Lịch sử đề xuất cấp vật tư phụ',
          children: <ProposalHistory type={type} />,
        },
        // {
        //   key: '3',
        //   label: 'Chi phí phát sinh ',
        //   children: <AdditionalCost type={type} />,
        // },
      ];
      break;
    case eTypeVatTuMayMoc.MayMoc:
      tabs = [
        {
          key: '1',
          label: 'Danh sách máy móc',
          children: <MachineryMaterialsList type={type} />,
        },
        {
          key: '2',
          label: 'Lịch sử đề xuất cấp máy móc',
          children: <ProposalHistory type={type} />,
        },
      ];
      break;
  }
  const handleAddProposal = () => {
    dispatch(accountingInvoiceActions.setProposalFormSelected(undefined));
    setIsModalVisible(true);
  };

  const handleDownload = () => {};

  const handleSelectDate = (dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
    if (dates) {
      const [startDate, endDate] = dates;
      if (startDate && endDate) {
        dispatch(accountingInvoiceActions.setDateRange({ startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') }));
      }
    } else {
      console.log('Khoảng thời gian chưa được chọn đầy đủ.');
    }
  };
  const handleModalClose = () => {
    const random = Utils.generateRandomString(3);
    dispatch(accountingInvoiceActions.setClearData(random));
    setIsModalVisible(false);
  };

  return (
    <div>
      <TabHeader
        tabs={tabs}
        onAddProposal={handleAddProposal}
        onDownload={handleDownload}
        onSelectDate={handleSelectDate}
      />
      <Modal open={isModalVisible} onCancel={handleModalClose} footer={null} width={1250}>
        <NewMachineryMaterialList type={type} session={Utils.generateRandomString(3)} handleClose={() => {
            setIsModalVisible(false);
          }}/>
      </Modal>
    </div>
  );
};

import { useEffect, useState } from 'react';

import { Empty, PaginationProps, Table, TableProps, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

// import Menucontext from './components/Menucontext';
import styles from './SalaryDepartment.module.less';
import { SecondSalaryHeader } from './SecondSalaryAdHeader';
import { issuesColumns } from '../KpiSalaryComponent/columns/IssuseColumn';
import { CreateUpdateIssue } from '../KpiSalaryComponent/CreateUpdateIssue';
import { CreateUpdateIssueModalName, defaultPagingParams, GettingIssueByVersionList } from '@/common/define';
import { Loading } from '@/components';
import { useWindowSize } from '@/hooks';
import { LableResponse } from '@/services/ProjectService';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getIssueQueryParams, issueActions } from '@/store/issue';
import { getLoading } from '@/store/loading';
import { getModalVisible, showModal } from '@/store/modal';
import { getSelectedProject, projectActions, getLabelChildren, getFileRoots } from '@/store/project';

export const SecondSalaryAdvance = ({ isFromHome: boolean = false }) => {
  const { t } = useTranslation('bidding');

  const windowSize = useWindowSize();

  const dispatch = useAppDispatch();
  const selectedProject = useAppSelector(getSelectedProject());
  const folderModel = useAppSelector(getModalVisible(CreateUpdateIssueModalName));
  const params = useAppSelector(getIssueQueryParams());
  const isLoading = useAppSelector(getLoading(GettingIssueByVersionList));
  const [dataInita, setDataInit] = useState<LableResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const ListLabelChild = useAppSelector(getLabelChildren());
  const listDataFileRoots = useAppSelector(getFileRoots());
  const [idFolder, setIdFolder] = useState<string>('');
  const uploadFileForFolder = useAppSelector(getLoading('uploadFileForFolder'));

  useEffect(() => {
    if (!ListLabelChild) {
      setLoading(true);
    }
    if (ListLabelChild && ListLabelChild && ListLabelChild.length > 0) {
      const newData = mappingData(ListLabelChild);
      setDataInit(newData);
      setLoading(false);
    }
  }, [ListLabelChild, ListLabelChild?.length, params, isLoading]);

  const mappingData = (ListLabelChild: any) => {
    const results: LableResponse[] = [];
    ListLabelChild?.forEach((item: any) => {
      const datamap: LableResponse = {
        ...item,
        subject: item.name,
      };
      results.push(datamap);
    });
    return results;
  };

  useEffect(() => {
    if (listDataFileRoots && listDataFileRoots?.results?.length > 0) {
      const rootId = listDataFileRoots.results.find((i: any) => i.name === 'ungluong2');
      setIdFolder(rootId?.id);
      if (rootId) {
        dispatch(projectActions.getLabel({ id: rootId?.id }));
      }
    }
  }, [listDataFileRoots]);

  const handleDownload = (file: any) => {
    dispatch(issueActions.downloadFileAttachmentOfIssue({ id: file.id, fileName: file.name }));
  };

  const editIssue = (file: any) => {
    dispatch(issueActions.setEditIssuePublics(true));
    dispatch(issueActions.setSelectedLabel(file));
    dispatch(showModal({ key: CreateUpdateIssueModalName }));
  };

  const handleIssueTableChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const search = { ...params, page: current, pageSize };
    if (selectedProject) {
      // dispatch(issueActions.getIssuesByMilestoneRequest({ projectId: selectedProject.id, params: search, tagVersionId: Utils.getMileStoneId(sMilestone.SalaryPaymentOne, tags), pageSize: 20 }));
    }
  };

  const showTotal: PaginationProps['showTotal'] = (total, range) =>
    t('Paging total', { range1: range[0], range2: range[1], total });

  const dataSource = dataInita;
  return (
    <>
      {folderModel && <CreateUpdateIssue labelid={idFolder} />}
      <SecondSalaryHeader />
      <div className={styles.wrapperbiding}>
        {ListLabelChild && ListLabelChild && ListLabelChild.length === 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 150px)',
              backgroundColor: 'white',
              margin: 10,
            }}
          >
            <Empty
              description={
                <>
                  <Typography.Title level={4}>{t('No data found based on filtering criteria')}</Typography.Title>
                </>
              }
            />
          </div>
        )}
        {ListLabelChild && ListLabelChild.length > 0 && (
          <>
            {loading ? (
              <Loading />
            ) : (
              <div style={{ padding: 10 }}>
                <Table
                  rowKey={record => record.id}
                  dataSource={dataSource!}
                  columns={issuesColumns({ t, editIssue, handleDownload })}
                  style={{ width: '100%' }}
                  size="small"
                  scroll={{ x: 1000, y: windowSize[1] - 310 }}
                  pagination={{
                    current: params?.page || defaultPagingParams.page,
                    pageSize: params?.pageSize || defaultPagingParams.pageSize,
                    total: ListLabelChild.length || 0,
                    responsive: true,
                    showTotal,
                    showSizeChanger: true,
                  }}
                  loading={uploadFileForFolder || isLoading}
                  onChange={handleIssueTableChange}
                  expandable={{
                    expandIcon: ({ expanded, onExpand, record }) => {
                      return null;
                    },
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

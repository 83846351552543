import { Outlet } from 'react-router-dom';

import { AuthRouteObject } from './AuthRoute';
import { eTypeVatTuMayMoc } from '@/common/define';
import { ComingSoon } from '@/components/ComingSoon';
import MainLayout from '@/components/Layout/MainLayout';
import ProjectLayout from '@/components/Layout/ProjectLayout';
import { Bidding, ConstructionManuals, LoginPage, ShiftTemplates } from '@/pages';
import NotFound from '@/pages/404';
import AdvancePlan from '@/pages/AdvancePlan12';
import { CapabilityProfile } from '@/pages/CapabilityProfile/CapabilityProfile';
import CompanyEmployee from '@/pages/CompanyEmployee';
import ConstructionMagazine from '@/pages/ConstructionMagazine';
import { ContractKpiBidding } from '@/pages/ContractKpiBidding';
import CostEstimate from '@/pages/CostEstimate';
import CreateProjectPage from '@/pages/CreateProjectPage';
import EnvironmentalSanitationDiary from '@/pages/EnvironmentalSanitationDiary';
import { FirstSalaryAdvance } from '@/pages/FirstSalaryAdvance';
import { FristSalaryPayment } from '@/pages/FirstSalaryPayment';
import { HomePage } from '@/pages/HomePage';
import MachineryMaterials from '@/pages/MachineryMaterials';
import Organization from '@/pages/Organization';
import { PreConstructionWork } from '@/pages/PreConstructionWork';
import { MainMaterial } from '@/pages/Project';
import { Machinery } from '@/pages/Project';
import { Auxiliarymaterial } from '@/pages/Project';
import { TransferMaterial } from '@/pages/Project';
import InventoryDepot from '@/pages/Project/InventoryDepot';
import ListSubcontract from '@/pages/Project/ListSubcontract';
import ProjectDocument from '@/pages/ProjectDocument';
import ProjectSettingPage from '@/pages/ProjectSettingPage';
import ProjectSettlement from '@/pages/ProjectSettlement';
import ProjectsPage from '@/pages/ProjectsPage';
import PublicPage from '@/pages/PublicPage';
import SafetyDiary from '@/pages/SafetyDiary';
import { SecondSalaryAdvance } from '@/pages/SecondSalaryAdvance';
import { SecondSalaryPayment } from '@/pages/SecondSalaryPayment';
import { AggregateCosts, PaySubcontractor, PaytheSubcontractors } from '@/pages/Subcontractor';
import TeamManagePage from '@/pages/TeamManagePage';
import { TimelineSection as Timekeeping } from '@/pages/Timekeeping';
import UnionDues from '@/pages/UnionDues';
import { WeeklyAssignment } from '@/pages/WeeklyAssignment';

type MetaMenu = {
  name?: string;
  icon?: React.ReactNode;
};

export type MetaMenuAuthRouteObject = AuthRouteObject<MetaMenu>;

export const projectRouters: MetaMenuAuthRouteObject[] = [
  {
    element: <ProjectsPage />,
    name: 'Project List',
    index: true,
  },
  {
    element: <ProjectLayout />,
    children: [
      {
        element: <Bidding />,
        path: '/projects/bidding',
      },
      {
        element: <ContractKpiBidding />,
        path: '/projects/contract-bid-kpis',
      },
      {
        element: <CostEstimate />,
        path: '/projects/prepare/cost-estimate',
      },
      {
        element: <PreConstructionWork />,
        path: '/projects/prepare/construction',
      },
      {
        element: <PublicPage />,
        path: '/projects/construction/init',
      },
      {
        element: <WeeklyAssignment />,
        path: '/projects/construction/weekly-assignment',
      },
      {
        element: <ConstructionManuals />,
        path: '/projects/construction/manuals',
      },
      {
        element: <ConstructionMagazine />,
        path: '/projects/construction/diary',
      },
      {
        element: <SafetyDiary />,
        path: '/projects/construction/labor-safety-diary',
      },
      {
        element: <EnvironmentalSanitationDiary />,
        path: '/projects/construction/enviromental-hygiene-diary',
      },
      // {
      //   element: <MaterialDiary />,
      //   path: '/projects/materials-log',
      // },
      // {
      //   element: <MachineLog />,
      //   path: '/projects/machinery-log',
      // },
      {
        element: <MachineryMaterials type={eTypeVatTuMayMoc.VatTuChinh}/>,
        name: 'Main material',
        path: '/projects/main-material',
      },
      {
        element: <MachineryMaterials type={eTypeVatTuMayMoc.VatTuPhu}/>,
        name: 'Auxiliary material',
        path: '/projects/auxiliary-material',
      },
      {
        element: <MachineryMaterials type={eTypeVatTuMayMoc.MayMoc}/>,
        name: 'Machinery',
        path: '/projects/machinery',
      },
      {
        element: <TransferMaterial />,
        name: 'Transfer-material',
        path: '/projects/transfer-material',
      },
      {
        element: <InventoryDepot />,
        name: 'Inventory report',
        path: '/projects/depot',
      },
      {
        element: <ListSubcontract />,
        name: 'Inventory report',
        path: '/projects/subcontractors/subcontract',
      },
      {
        element: <PaytheSubcontractors />,
        name: 'Inventory report',
        path: '/projects/subcontractors/pay-the-subcontractor-12',
      },
      {
        element: <PaySubcontractor />,
        name: 'Inventory report',
        path: '/projects/subcontractors/pay-the-subcontractor-17',
      },
      {
        element: <AggregateCosts />,
        name: 'Inventory report',
        path: '/projects/subcontractors/aggregate-costs',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/employees/overtime',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/employees/transfer',
      },
      {
        element: <ComingSoon />,
        name: 'Inventory report',
        path: '/projects/employees/statistic-bonus',
      },
      {
        element: <TeamManagePage />,
        name: 'Team manage',
        path: '/projects/employees/team-manage',
      },
      {
        element: <Outlet />,
        name: 'Project management',
        path: '/projects/project-management',
        children: [
          {
            element: <ProjectDocument />,
            path: '/projects/project-management/documents',
          },
          {
            element: <ComingSoon />,
            path: '/projects/project-management/contruction-expenses',
          },
        ],
      },
      {
        element: <Outlet />,
        name: 'KPI - Salary',
        path: '/projects/kpi-salary',
        children: [
          {
            element: <ComingSoon />,
            path: '/projects/kpi-salary/kpis-of-departments',
          },
          {
            element: <ComingSoon />,
            path: '/projects/kpi-salary/salary-of-departments',
          },
          {
            element: <FirstSalaryAdvance />,
            path: '/projects/kpi-salary/1st-salary-advance',
          },
          {
            element: <FristSalaryPayment />,
            path: '/projects/kpi-salary/1st-salary-payment',
          },
          {
            element: <SecondSalaryAdvance />,
            path: '/projects/kpi-salary/2nd-salary-advance',
          },
          {
            element: <SecondSalaryPayment />,
            path: '/projects/kpi-salary/2nd-salary-payment',
          },
          {
            element: <ComingSoon />,
            path: '/projects/kpi-salary/year-end-bonus',
          },
        ],
      },
      {
        element: <ProjectSettingPage />,
        path: '/projects/project-settings',
      },
      {
        element: <Outlet />,
        name: 'Employees',
        path: '/projects/employees',
        children: [
          {
            element: <Timekeeping />,
            name: 'Check-in time',
            path: '/projects/employees/check-in-time',
          },
        ],
      },
    ],
  },
];

export const routers: MetaMenuAuthRouteObject[] = [
  {
    element: <MainLayout />,
    path: '/',
    name: 'Main',
    children: [
      {
        element: <ComingSoon />,
        path: 'dashboard',
      },
      {
        element: <HomePage />,
        name: 'Home',
        index: true,
      },
      {
        element: <Organization />,
        name: 'Organization',
        path: '/organization',
      },
      {
        element: <CreateProjectPage />,
        name: 'Create Project',
        path: '/create-project',
      },
      {
        element: <Outlet />,
        name: 'Projects',
        path: '/projects',
        children: projectRouters,
      },
      {
        element: <ShiftTemplates />,
        name: 'Shift Templates',
        path: '/shift-templates',
      },
      {
        element: <CapabilityProfile />,
        name: 'Capability Profile',
        path: '/capability-profile',
      },
      {
        element: <Outlet />,
        name: 'Store - Material - Machinery',
        path: '/material-machinery',
        children: [
          {
            element: <MachineryMaterials type={eTypeVatTuMayMoc.VatTuChinh}/>,
            name: 'Main material',
            path: '/material-machinery/main-material',
          },
          {
            element: <MachineryMaterials type={eTypeVatTuMayMoc.VatTuPhu}/>,
            name: 'Auxiliary material',
            path: '/material-machinery/auxiliary-material',
          },
          {
            element: <MachineryMaterials type={eTypeVatTuMayMoc.MayMoc}/>,
            name: 'Machinery',
            path: '/material-machinery/machinery',
          },
          // {
          //   element: <ComingSoon />,
          //   name: 'Materials log',
          //   path: '/material-machinery/materials-log',
          // },
          // {
          //   element: <ComingSoon />,
          //   name: 'Machinery log',
          //   path: '/material-machinery/machinery-log',
          // },
          {
            element: <TransferMaterial />,
            name: 'Transfer material',
            path: '/material-machinery/transfer-material',
          },
          {
            element: <InventoryDepot />,
            name: 'Depot',
            path: '/material-machinery/depot',
          },
        ],
      },
      {
        element: <Outlet />,
        name: 'KPI - Salary',
        path: '/kpi-salary',
        children: [
          {
            element: <ComingSoon />,
            name: 'KPIs of departments',
            path: '/kpi-salary/kpis-of-departments',
          },
          {
            element: <ComingSoon />,
            name: 'Salary of departments',
            path: '/kpi-salary/salary-of-departments',
          },
          {
            element: <FirstSalaryAdvance isFromHome={true} />,
            name: '1st salary advance',
            path: '/kpi-salary/1st-salary-advance',
          },
          {
            element: <FristSalaryPayment isFromHome={true} />,
            name: '1st salary payment',
            path: '/kpi-salary/1st-salary-payment',
          },
          {
            element: <SecondSalaryAdvance isFromHome={true} />,
            name: '2nd salary advance',
            path: '/kpi-salary/2nd-salary-advance',
          },
          {
            element: <SecondSalaryPayment isFromHome={true} />,
            name: '2nd salary payment',
            path: '/kpi-salary/2nd-salary-payment',
          },
          {
            element: <ComingSoon />,
            name: 'Year-end bonus',
            path: '/kpi-salary/year-end-bonus',
          },
        ],
      },
      {
        element: <Outlet />,
        name: 'Employee',
        path: '/employee',
        children: [
          {
            element: <CompanyEmployee />,
            name: 'Company employee',
            path: '/employee/company-employee',
          },
          {
            element: <ComingSoon />,
            name: 'Company group',
            path: '/employee/company-group',
          },
        ],
      },
      {
        element: <Outlet />,
        name: 'Union welfare funds',
        path: '/union-welfare-funds',
        children: [
          {
            element: <UnionDues />,
            name: 'Union fee collection report',
            path: '/union-welfare-funds/fee',
          },
          {
            element: <ComingSoon />,
            name: 'Union funds expenses report',
            path: '/union-welfare-funds/expenses',
          },
          {
            element: <ComingSoon />,
            name: 'Tet and holiday bonus expenses',
            path: '/union-welfare-funds/bonus',
          },
          {
            element: <ComingSoon />,
            name: 'Recurring travel expenses',
            path: '/union-welfare-funds/recurring-travel-expenses',
          },
        ],
      },
      {
        element: <Outlet />,
        name: 'Management accounting',
        path: '/management-accounting',
        children: [
          {
            element: <AdvancePlan />,
            name: 'Make an advance plan 12th',
            path: '/management-accounting/make-an-advance-plan-12th',
          },
          {
            element: <ComingSoon />,
            name: 'Payment plan 20th',
            path: '/management-accounting/20th-payment-plan',
          },
          {
            element: <ComingSoon />,
            name: 'Make an advance plan 27th',
            path: '/management-accounting/make-an-advance-plan-27th',
          },
          {
            element: <ComingSoon />,
            name: 'Payment plan 5th',
            path: '/management-accounting/5th-payment-plan',
          },
          {
            element: <ComingSoon />,
            name: 'Aggregate materials',
            path: '/management-accounting/aggregate-materials',
          },
          {
            element: <ComingSoon />,
            name: 'Track cash flow',
            path: '/management-accounting/track-cash-flow',
          },
          {
            element: <ComingSoon />,
            name: 'Bookkeeping',
            path: '/management-accounting/bookkeeping',
          },
          {
            element: <ComingSoon />,
            name: 'Input invoices',
            path: '/management-accounting/input-invoices',
          },
          {
            element: <ComingSoon />,
            name: 'Output invoices',
            path: '/management-accounting/output-invoices',
          },
          {
            element: <ComingSoon />,
            name: 'Invoices difference',
            path: '/management-accounting/invoices-difference',
          },
          {
            element: <ComingSoon />,
            name: 'Invoice X',
            path: '/management-accounting/invoice-x',
          },
          {
            element: <ComingSoon />,
            name: 'ĐMDT / HĐ đầu vào + X',
            path: '/management-accounting/input-invoices-and-x',
          },
          {
            element: <ComingSoon />,
            name: 'Compare norms',
            path: '/management-accounting/compare-norms',
          },
          {
            element: <ComingSoon />,
            name: 'Supplier - Investor debt',
            path: '/management-accounting/supplier-investor-debt',
          },
          {
            element: <ProjectSettlement />,
            name: 'Profit and loss statement',
            path: '/management-accounting/profit-and-loss-statement',
          },
          {
            element: <ComingSoon />,
            name: 'Development chart',
            path: '/management-accounting/develoment-chart',
          },
          {
            element: <ComingSoon />,
            name: 'Other cost charts',
            path: '/management-accounting/Other-cost-charts',
          },
          {
            element: <ComingSoon />,
            name: 'Profit chart',
            path: '/management-accounting/profit-chart',
          },
        ],
      },
      {
        element: <Outlet />,
        name: 'Review',
        path: '/review',
        children: [
          {
            element: <ComingSoon />,
            name: 'Project management - suppliers',
            path: '/review/project-management-suppliers',
          },
          {
            element: <ComingSoon />,
            name: 'Supervision consultants - supplier',
            path: '/review/supervision-consultants-suppliers',
          },
          {
            element: <ComingSoon />,
            name: 'Investors - Project management',
            path: '/review/investors-project-management',
          },
          {
            element: <ComingSoon />,
            name: 'Investors - Supervision consultants',
            path: '/review/investors-supervision-consultant',
          },
          {
            element: <ComingSoon />,
            name: 'Other',
            path: '/review/Other',
          },
        ],
      },
    ],
  },
  {
    element: <LoginPage />,
    name: 'Login',
    path: '/login',
  },
  { path: '*', element: <NotFound /> },
];

/* eslint-disable import/order */
import { catchError, concat, filter, mergeMap, switchMap, withLatestFrom } from 'rxjs';

import { accountingInvoice, defaultPagingParams, eStatusRequest, FormatDateAPI } from '@/common/define';
import { AccountingInvoiceService } from '@/services/AccountingInvoiceService';
import Utils from '@/utils';
import dayjs from 'dayjs';
import { accountingInvoiceActions, getDateRange } from '../accountingInvoice';
import { startLoading, stopLoading } from '../loading';
import { RootEpic } from '../types';

const GetDieuChuyenVatTu$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetDieuChuyenVatTu.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { madvcs, tu_ngay, den_ngay, ma_kho } = action.payload;
      return concat(
        [startLoading({ key: accountingInvoiceActions.GetDieuChuyenVatTu.type })],
        AccountingInvoiceService.Post.GetDanhSachDieuChuyenHangHoaVatTu(madvcs, tu_ngay, den_ngay, ma_kho).pipe(
          switchMap(results => {
            return [accountingInvoiceActions.setDieuchuyenvattu(results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoiceActions.GetDieuChuyenVatTu.type })],
      );
    }),
  );
};
const CreatePhieuDieuChuyen$: RootEpic = action$ => {
  return action$.pipe(
    filter(accountingInvoiceActions.CreatePhieuDieuChuyen.match),
    switchMap(action => {
      const { data }: any = action.payload;
      const tu_ngay = dayjs().startOf('month').format(FormatDateAPI);
      const den_ngay = dayjs().endOf('month').format(FormatDateAPI);
      const ma_kho = data.chiTietHangHoa[0]?.ma_kho || '';
      return concat(
        [startLoading({ key: 'CreatePhieuDieuChuyen' })],
        AccountingInvoiceService.Post.CreatePhieuDieuChuyen(data).pipe(
          switchMap(repon => {
            Utils.successNotification('Tạo phiếu thành công');
            return [
              accountingInvoiceActions.CreatePhieuDieuChuyenSuccess(repon),
              accountingInvoiceActions.GetDieuChuyenVatTu({madvcs: 'THUCHIEN', tu_ngay, den_ngay, ma_kho}),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'CreatePhieuDieuChuyen' })],
      );
    }),
  );
};
const CreatePhieuNhapKho$: RootEpic = action$ => {
  return action$.pipe(
    filter(accountingInvoiceActions.CreatePhieuNhapKho.match),
    switchMap(action => {
      const { data }: any = action.payload;
      return concat(
        [startLoading({ key: 'CreatePhieuDieuChuyen' })],
        AccountingInvoiceService.Post.CreatePhieuDieuChuyen(data).pipe(
          switchMap(response => {
            Utils.successNotification('Tạo phiếu thành công');
            return [
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'CreatePhieuDieuChuyen' })],
      );
    }),
  );
};

const CreatePhieuXuatKho$: RootEpic = action$ => {
  return action$.pipe(
    filter(accountingInvoiceActions.CreatePhieuXuatKho.match),
    switchMap(action => {
      const { data }: any = action.payload;
      return concat(
        [startLoading({ key: 'CreatePhieuXuatKho' })],
        AccountingInvoiceService.Post.CreatePhieuDieuChuyen(data).pipe(
          switchMap(response => {
            Utils.successNotification('Tạo phiếu xuất kho thành công');
            return [
              accountingInvoiceActions.CreatePhieuDieuChuyenSuccess(response),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'CreatePhieuXuatKho' })],
      );
    }),
  );
};
const CreatePhieuNhapKhodc$: RootEpic = action$ => {
  return action$.pipe(
    filter(accountingInvoiceActions.CreatePhieuNhapKhodc.match),
    switchMap(action => {
      const { data }: any = action.payload;
      return concat(
        [startLoading({ key: 'CreatePhieuNhapKho' })],
        AccountingInvoiceService.Post.CreatePhieuDieuChuyen(data).pipe(
          switchMap(response => {
            Utils.successNotification('Tạo phiếu nhập kho thành công');
            return [
              accountingInvoiceActions.CreatePhieuDieuChuyenSuccess(response),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'CreatePhieuNhapKho' })],
      );
    }),
  );
};
const getProductRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetProducts.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.getProducts })],
        AccountingInvoiceService.Get.GetProduct({ search: { ...params } }).pipe(
          mergeMap(results => {
            return [accountingInvoiceActions.SetProducts(results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.SetProducts(undefined)];
          }),
        ),
        [stopLoading({ key: accountingInvoice.getProducts })],
      );
    }),
  );
};

const getWareHouse$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetWareHouse.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.getWareHouse })],
        AccountingInvoiceService.Get.GetWareHouse({ search: { ...params } }).pipe(
          mergeMap(results => {
            return [accountingInvoiceActions.SetWareHouse(results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.SetWareHouse(undefined)];
          }),
        ),
        [stopLoading({ key: accountingInvoice.getWareHouse })],
      );
    }),
  );
};

const GetProductUnit$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetProductUnit.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetProductUnit })],
        AccountingInvoiceService.Get.GetProductUnit({ search: { ...params } }).pipe(
          mergeMap(results => {
            return [accountingInvoiceActions.setProductUnits(results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.setProductUnits(undefined)];
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetProductUnit })],
      );
    }),
  );
};

const GetDanhSachThietBi$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetDanhSachThietBi.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetDanhSachThietBi })],
        AccountingInvoiceService.Get.GetDanhSachThietBi({ search: { ...params } }).pipe(
          mergeMap(results => {
            return [accountingInvoiceActions.setMachineries(results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.setMachineries(undefined)];
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetDanhSachThietBi })],
      );
    }),
  );
};

const GetMoneyTypeList$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetMoneyTypeList.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetMoneyTypeList })],
        AccountingInvoiceService.Get.GetMoneyTypeList({ search: { ...params } }).pipe(
          mergeMap(results => {
            return [accountingInvoiceActions.setMoneyTypes(results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.setMoneyTypes(undefined)];
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetMoneyTypeList })],
      );
    }),
  );
};

//#region PhieuNhapXuatKho
const GetDanhSachDuyetChi$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetDanhSachDuyetChi.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetDanhSachDuyetChi })],
        AccountingInvoiceService.Get.GetDanhSachDuyetChi({ search: { ...params } }).pipe(
          mergeMap(results => {
            return [
              accountingInvoiceActions.setDanhSachDuyetChi(results),
              accountingInvoiceActions.setQuery_danhSachDuyetChi({ params }),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [
              accountingInvoiceActions.setDanhSachDuyetChi(undefined),
              accountingInvoiceActions.setQuery_danhSachDuyetChi({ params: undefined }),
            ];
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetDanhSachDuyetChi })],
      );
    }),
  );
};

const GetDanhSachDuyetMuaHang$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetDanhSachDuyetMuaHang.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetDanhSachDuyetMuaHang })],
        AccountingInvoiceService.Get.GetDanhSachDuyetMuaHang({ search: { ...params } }).pipe(
          mergeMap((results: any) => {
            //         var data = results
            // .replaceAll('\\"', '"')   // Replace escaped double quotes
            // .replaceAll(/"\[|\]"/g, (match: string) => match.replace(/"/g, '')) // Remove quotes before/after brackets
            // .replaceAll(/\n/g, '') // Remove both newline and carriage return characters
            // .replaceAll(/\r/g, ''); // Remove both newline and carriage return characters
            // console.log(jsonParse, 'jsonParse');
            console.log('here', results);
            return [
              accountingInvoiceActions.setDanhSachDuyetMuaHang(results),
              accountingInvoiceActions.setQuery_danhSachDuyetMuaHang({ params }),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [
              accountingInvoiceActions.setDanhSachDuyetMuaHang(undefined),
              accountingInvoiceActions.setQuery_danhSachDuyetMuaHang({ params: undefined }),
            ];
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetDanhSachDuyetMuaHang })],
      );
    }),
  );
};

const DuyetChi$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.DuyetChi.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { data, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.DuyetChi })],
        AccountingInvoiceService.Post.DuyetChi(data, { search: { ...params } }).pipe(
          mergeMap(results => {
            return [
              accountingInvoiceActions.GetDanhSachDuyetChi({ params: state.accountingInvoice.query_danhSachDuyetChi }),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.DuyetChi })],
      );
    }),
  );
};

const HuyDuyetChi$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.HuyDuyetChi.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { data, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.HuyDuyetChi })],
        AccountingInvoiceService.Post.HuyDuyetChi(data, { search: { ...params } }).pipe(
          mergeMap(results => {
            return [
              accountingInvoiceActions.GetDanhSachDuyetChi({ params: state.accountingInvoice.query_danhSachDuyetChi }),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.HuyDuyetChi })],
      );
    }),
  );
};

const CreatePhieuNhapXuatKho$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.CreatePhieuNhapXuatKho.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { data, params } = action.payload;
      return concat(
        [startLoading({ key: accountingInvoice.CreatePhieuNhapXuatKho })],
        AccountingInvoiceService.Post.CreatePhieuNhapXuatKho(data).pipe(
          switchMap(d => {
            Utils.successNotification();
            return [
              accountingInvoiceActions.GetDanhSachDuyetChi({ params: state.accountingInvoice.query_danhSachDuyetChi }),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.CreatePhieuNhapXuatKho })],
      );
    }),
  );
};
const DeletePhieuNhapXuatKho$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.DeletePhieuNhapXuatKho.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { ids, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, page: 1 };
      return concat(
        [startLoading({ key: accountingInvoice.DeletePhieuNhapXuatKho })],
        AccountingInvoiceService.Post.DeletePhieuNhapXuatKho(ids).pipe(
          switchMap(() => {
            Utils.successNotification();
            return [
              accountingInvoiceActions.GetDanhSachDuyetChi({ params: state.accountingInvoice.query_danhSachDuyetChi }),
            ];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.DeletePhieuNhapXuatKho })],
      );
    }),
  );
};
//#region Tồn kho
const GetTonKho$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetTonKho.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { data, params, TonKhoTheoNgay } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetTonKho })],
        AccountingInvoiceService.Post.GetTonKho(data, { search: { ...params } }).pipe(
          mergeMap(results => {
            if (TonKhoTheoNgay) {
              return [accountingInvoiceActions.setTonKhoTheoNgay(results)];
            } else {
              return [accountingInvoiceActions.setTonkho(results)];
            }
          }),
          catchError(error => {
            Utils.errorHandling(error);
            if (TonKhoTheoNgay) {
              return [accountingInvoiceActions.setTonKhoTheoNgay(undefined)];
            } else {
              return [accountingInvoiceActions.setTonkho(undefined)];
            }
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetTonKho })],
      );
    }),
  );
};

//#region ProposalForm
const GetProposalForm$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetProposalForm.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetProposalForm })],
        AccountingInvoiceService.Get.GetProposalForm({ search: { ...params } }).pipe(
          mergeMap(results => {
            return [accountingInvoiceActions.setProposalForms(results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.setProposalForms(undefined)];
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetProposalForm })],
      );
    }),
  );
};

const GetDanhSachPhieuDeNghiMuaHang_ChiTietHangHoa$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetDanhSachPhieuDeNghiMuaHang_ChiTietHangHoa.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetDanhSachPhieuDeNghiMuaHang_ChiTietHangHoa })],
        AccountingInvoiceService.Get.GetDanhSachPhieuDeNghiMuaHang_ChiTietHangHoa({ search: { ...params } }).pipe(
          mergeMap(results => {
            var data = results
              .replaceAll('\\"', '"') // Replace escaped double quotes
              .replaceAll(/"\[|\]"/g, (match: string) => match.replace(/"/g, '')) // Remove quotes before/after brackets
              .replaceAll(/\n/g, '') // Remove both newline and carriage return characters
              .replaceAll(/\r/g, ''); // Remove both newline and carriage return characters
            const jsonParse = JSON.parse(data);
            return [accountingInvoiceActions.setChiTietHangHoa(jsonParse)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.setChiTietHangHoa(undefined)];
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetDanhSachPhieuDeNghiMuaHang_ChiTietHangHoa })],
      );
    }),
  );
};
const ConfirmProposalForm$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.ConfirmProposalForm.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { data, params } = action.payload;
      return concat(
        [startLoading({ key: accountingInvoice.ConfirmProposalForm })],
        AccountingInvoiceService.Post.CreateProposalForm(data, { search: { ...params } }).pipe(
          switchMap(d => {
            Utils.successNotification();
            return [
              // accountingInvoiceActions.GetProposalForm({
              //   params: {
              //     madvcs: 'KEHOACH',
              //     ngay_de_nghi_tu_ngay: '2024-09-23',
              //     ngay_de_nghi_den_ngay: '2024-09-23',
              //     ma_kho: 'TONG'
              //   },
              // }),
              accountingInvoiceActions.GetDanhSachDuyetMuaHang({params: state.accountingInvoice.query_danhSachDuyetMuaHang.params}),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.ConfirmProposalForm })],
      );
    }),
  );
};
const CreateProposalForm$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.CreateProposalForm.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { data, params } = action.payload;
      return concat(
        [startLoading({ key: accountingInvoice.CreateProposalForm })],
        AccountingInvoiceService.Post.CreateProposalForm(data, { search: { ...params } }).pipe(
          switchMap(d => {
            Utils.successNotification();
            const p = state.accountingInvoice.query_danhSachDuyetMuaHang?.params
            if (p) {
              return [
                accountingInvoiceActions.setStatusRequest({api: accountingInvoice.CreateProposalForm, status: eStatusRequest.success}),
                accountingInvoiceActions.GetDanhSachDuyetMuaHang({params: p}),
              ];
            }
            return [
              accountingInvoiceActions.setStatusRequest({api: accountingInvoice.CreateProposalForm, status: eStatusRequest.success}),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [
              accountingInvoiceActions.setStatusRequest({
                api: accountingInvoice.CreateProposalForm,
                status: eStatusRequest.error,
              }),
            ];
          }),
        ),
        [stopLoading({ key: accountingInvoice.CreateProposalForm })],
      );
    }),
  );
};
const UpdateProposalForm$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.UpdateProposalForm.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { id, data } = action.payload;
      return concat(
        [startLoading({ key: accountingInvoice.UpdateProposalForm })],
        AccountingInvoiceService.Put.UpdateProposalForm(id, data, {}).pipe(
          switchMap(() => {
            return [
              // accountingInvoiceActions.GetProposalForm({
              //   params: {
              //     madvcs: '',
              //     ngay_de_nghi_tu_ngay: '',
              //     ngay_de_nghi_den_ngay: '',
              //     ma_kho: ''
              //   },
              // }),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.UpdateProposalForm })],
      );
    }),
  );
};
const DeleteProposalForm$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.DeleteProposalForm.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { ids, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, page: 1 };
      return concat(
        [startLoading({ key: accountingInvoice.DeleteProposalForm })],
        AccountingInvoiceService.Post.DeleteProposalForm(ids, {}).pipe(
          switchMap(() => {
            Utils.successNotification();
            return [
              // accountingInvoiceActions.GetProposalForm({
              //   params: {
              //     madvcs: '',
              //     ngay_de_nghi_tu_ngay: '',
              //     ngay_de_nghi_den_ngay: '',
              //     ma_kho: ''
              //   },
              // }),
              
            ];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.DeleteProposalForm })],
      );
    }),
  );
};

const splitDeNghiMuaHangTheoNhaCungCap$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.splitDeNghiMuaHangTheoNhaCungCap.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, page: 1 };
      return concat(
        [startLoading({ key: accountingInvoice.SplitDeNghiMuaHangTheoNhaCungCap })],
        AccountingInvoiceService.Post.SplitDeNghiMuaHangTheoNhaCungCap(params.guid).pipe(
          switchMap(() => {
            Utils.successNotification();
            return [
              // accountingInvoiceActions.GetProposalForm({
              //   params: {
              //     madvcs: '',
              //     ngay_de_nghi_tu_ngay: '',
              //     ngay_de_nghi_den_ngay: '',
              //     ma_kho: ''
              //   },
              // }),
              accountingInvoiceActions.GetDanhSachDuyetMuaHang({params: state.accountingInvoice.query_danhSachDuyetMuaHang.params}),
            ];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.SplitDeNghiMuaHangTheoNhaCungCap })],
      );
    }),
  );
};

const DeletePhieuDeNghiMuaHang$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.DeletePhieuDeNghiMuaHang.match), 
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { ids, params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, page: 1 };
      const dateRange = getDateRange();
      console.log('Deleted');
      return concat(
        [startLoading({ key: accountingInvoice.DeleteDanhSachDuyetMuaHang })],
        AccountingInvoiceService.Post.DeletePhieuDeNghiMuaHang(ids, {}).pipe(
          switchMap(() => {
            Utils.successNotification();
            return [
              accountingInvoiceActions.GetDanhSachDuyetMuaHang({params: state.accountingInvoice.query_danhSachDuyetMuaHang.params}),
            ];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.DeleteDanhSachDuyetMuaHang })],
      );
    }),
  );
};
const getDanhSachBoPhanRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.GetDanhSachBoPhan.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      const search = { ...defaultPagingParams, ...state.issue.queryParams, ...params };
      return concat(
        [startLoading({ key: accountingInvoice.GetDanhSachBoPhan })],
        AccountingInvoiceService.Get.GetDanhSachBoPhan({ search: { ...params } }).pipe(
          mergeMap(results => {
            return [accountingInvoiceActions.SetDanhSachBoPhan(results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [accountingInvoiceActions.SetDanhSachBoPhan(undefined)];
          }),
        ),
        [stopLoading({ key: accountingInvoice.GetDanhSachBoPhan })],
      );
    }),
  );
};

const getBaoCaoXuatNhapTon$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(accountingInvoiceActions.getBaoCaoXuatNhapTon.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { params } = action.payload;
      return concat(
        [startLoading({ key: accountingInvoice.getBaoCaoXuatNhapTon })],
        AccountingInvoiceService.Post.getBaoCaoXuatNhapTon(params.data).pipe(
          switchMap(results => {
            try {
              const parsedData = JSON.parse(results);
              return [
                accountingInvoiceActions.setBaoCaoXuatNhapTon(parsedData)
              ];
            } catch (error) {
              return [
                accountingInvoiceActions.setBaoCaoXuatNhapTon(undefined)
              ];
            }
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: accountingInvoice.getBaoCaoXuatNhapTon })],
      );
    }),
  );
};

export const accountingInvoiceEpics = [
  getProductRequest$,
  getWareHouse$,
  GetMoneyTypeList$,
  GetDanhSachThietBi$,
  GetProductUnit$,
  GetProposalForm$,
  CreateProposalForm$,
  DeleteProposalForm$,
  UpdateProposalForm$,
  CreatePhieuNhapXuatKho$,
  DeletePhieuNhapXuatKho$,
  GetDanhSachDuyetChi$,
  GetDanhSachDuyetMuaHang$,
  GetTonKho$,
  DuyetChi$,
  DeletePhieuDeNghiMuaHang$,
  HuyDuyetChi$,
  GetDanhSachPhieuDeNghiMuaHang_ChiTietHangHoa$,
  ConfirmProposalForm$,
  GetDieuChuyenVatTu$,
  CreatePhieuDieuChuyen$,
  CreatePhieuNhapKho$,
  getDanhSachBoPhanRequest$,
  CreatePhieuXuatKho$,
  CreatePhieuNhapKhodc$,
  splitDeNghiMuaHangTheoNhaCungCap$,
  getBaoCaoXuatNhapTon$
];

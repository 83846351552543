/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';

import { eTypeVatTu, eTypeVatTuMayMoc } from '@/common/define';
import { ChiTietDeNghiMuaHangDTO } from '@/services/AccountingInvoiceService';
import {
  accountingInvoiceActions,
  getDanhSachDuyetMuaHang,
  getDateRange,
  getMayMoc,
  getProducts
} from '@/store/accountingInvoice';
import { getgetUserIIS } from '@/store/app';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import Utils from '@/utils';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Empty, Modal, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import MachineryMaterialsConfirm from '../MachineryMaterialsConfirm';
import NewMachineryMaterialList from '../NewMachineryMaterialList';
import styles from './ProposalHistory.module.less';
const { Text } = Typography;
export interface ProposalData {
  id: number;
  del: boolean;
  madvcs: string;
  recId: number;
  ma_ct: string;
  ngay_ct: string;
  so_ct: string;
  loai_tt: number;
  han_tt: string;
  ma_kh: string;
  ma_bo_phan: string;
  nguoi_tt: string;
  nv_bh: string;
  dia_chi: string;
  dien_giai: string;
  ma_nt: string;
  ty_gia: number;
  info: string;
  is_local: boolean;
  release: boolean;
  moduleName: string;
  createDate: string;
  capDuyet: number;
  capDuyetHienTai?: number;
  customerName: string;
  customerAddress: string;
  customerPhone: string;
  recIdparent: number;
  nguoiDuyet1: string;
  nguoiDuyet2: string;
  nguoiDuyet3: string;
  recIdrelation: number;
  guid: string;
  nguoiDuyet4: string;
  nguoiDuyet5: string;
  guidRelation: string;
  chiTietHangHoa: any; // Assuming null can later be replaced with specific type
  hoaDonVAT: any; // Assuming null can later be replaced with specific type
  list_of_extensions: any; // Assuming null can later be replaced with specific type
  chiTietDeNghiMuaHang: ChiTietDeNghiMuaHangDTO[];
  color?: string;
}
interface DayDataType {
  date: string;
  proposals: ProposalData[];
}

const calculateCapDuyet = (proposal: ProposalData): number => {
  return proposal.nguoiDuyet1 === ''
    ? 0
    : proposal.nguoiDuyet2 === ''
    ? 1
    : proposal.nguoiDuyet3 === ''
    ? 2
    : proposal.nguoiDuyet4 === ''
    ? 3
    : proposal.nguoiDuyet5 === ''
    ? 4
    : 5;
};
const groupByDate = (data: ProposalData[]): DayDataType[] => {
  const groupedData = data.reduce((acc, item) => {
    // Lấy ngày từ CreateDate
    const date = dayjs(item.createDate).format('DD/MM/YYYY');

    // Kiểm tra xem ngày đã tồn tại trong acc chưa
    if (!acc[date]) {
      acc[date] = [];
    }

    // Thêm proposal vào mảng của ngày tương ứng
    acc[date].push(item);

    return acc;
  }, {} as Record<string, ProposalData[]>);

  // Chuyển đổi đối tượng thành mảng các đối tượng DayDataType
  const sortedData = Object.keys(groupedData)
    .map(date => ({
      date,
      proposals: groupedData[date],
    }))
    .sort((a, b) => dayjs(a.date, 'DD/MM/YYYY').unix() - dayjs(b.date, 'DD/MM/YYYY').unix()); // Sắp xếp theo thời gian tăng dần

  return sortedData;
}

const colors = {
  0: 'red',     // Cấp 0 - Đỏ
  1: 'orange',  // Cấp 1 - Cam
  2: 'green',   // Cấp 2 - Xanh lá
  3: 'blue',    // Cấp 3 - Xanh dương
  4: 'yellow',    // Cấp 4 - Màu vàng
  5: 'gray',
};

const ProposalCard: React.FC<{ proposal: ProposalData; type: eTypeVatTuMayMoc; onReload: () => void }> = ({ proposal, type, onReload }) => {
  const { t } = useTranslation('proposalhistory');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false); // New state for delete confirmation modal
  const dispatch = useAppDispatch();
  const userIIS = useAppSelector(getgetUserIIS());

  // Xác định CapDuyet
  const CapDuyet = proposal.nguoiDuyet1 === ''
    ? 0
    : proposal.nguoiDuyet2 === ''
    ? 1
    : proposal.nguoiDuyet3 === ''
    ? 2
    : proposal.nguoiDuyet4 === ''
    ? 3
    : proposal.nguoiDuyet5 === ''
    ? 4
    : 5;
    proposal.capDuyetHienTai = CapDuyet;
    if (CapDuyet >= proposal.capDuyet) {
      proposal.capDuyetHienTai = 5;
    }
  const color = colors[proposal.capDuyetHienTai as keyof typeof colors]; // Default to gray if capDuyet is out of bounds

  const disableApproveButton = userIIS && userIIS[0] ? userIIS[0].capDuyetChi < CapDuyet : true;
  const disableEditButton = CapDuyet >= 1;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showModalEdit = (value: boolean) => {
    if (value) {
      let _proposal = { ...proposal };
      delete (_proposal as any).capDuyetHienTai;
    
      dispatch(accountingInvoiceActions.setProposalFormSelected(_proposal as any));
    } else {
      dispatch(accountingInvoiceActions.setProposalFormSelected(undefined));
    }
    setIsModalEdit(value);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDelete = () => {
    // Dispatch delete action and hide confirmation modal
    dispatch(accountingInvoiceActions.DeletePhieuDeNghiMuaHang({ ids: [proposal.guid], params: {} }));
    setIsDeleteConfirmVisible(false); // Hide the delete confirmation modal after deletion
  };

  const formatDateString = (dateString: string): string => {
    // Tách phần ngày và thời gian
    const [datePart, timePart] = dateString.split('T');
    
    // Loại bỏ phần mili giây từ thời gian
    const formattedTime = timePart.split('.')[0];
  
    // Kết hợp lại thành chuỗi theo định dạng yêu cầu
    return `${datePart} ${formattedTime}`;
  };
  
  return (
    <>
      <Card
        className={`${styles.proposalCard} ${styles[color]}`}
        title={
          <>
            <div className={styles.proposalCardHeader}>
              <Text className={styles.project}>{proposal.dien_giai}</Text>
              <Space className={styles.buttonGroup}>
                <Button
                  className={styles.noBorderButton}
                  style={{ backgroundColor: '#1890FF', height: 24 }}
                  onClick={showModal}
                  disabled={disableApproveButton}
                >
                  <Text style={{ color: 'white', paddingLeft: 5, paddingRight: 5 }} type="secondary">
                    Duyệt
                  </Text>
                </Button>
                <Button
                  icon={<EditOutlined />}
                  className={styles.noBorderButton}
                  style={{ color: '#1890FF' }}
                  onClick={() => showModalEdit(true)}
                  disabled={disableEditButton}
                />
                <Button
                  icon={<DeleteOutlined />}
                  className={styles.noBorderButton}
                  style={{ color: 'red' }}
                  onClick={() => setIsDeleteConfirmVisible(true)} // Show delete confirmation modal
                />
              </Space>
            </div>
            <Space className={styles.proposalCardFooter}>
              <Text className={styles.id}>Mã chứng từ: {proposal.ma_ct}</Text>
            </Space>
          </>
        }
      >
        <div className={styles.proposalCardBody}>
          <Text className={styles.textWhite}>
            <strong>{t('proposer')}:</strong> {proposal.nv_bh}
          </Text>
          <br />
          <Text className={styles.textWhite}>
            <strong>Thời gian tạo: </strong> {formatDateString(proposal.createDate)}
          </Text>
          <br />
          <Text className={styles.textWhite}>
            <strong>Cấp duyệt hiện tại:</strong> {proposal.capDuyetHienTai === 5 ? "Nhập kho" : proposal.capDuyetHienTai}
          </Text>
          <br />
          <Text className={styles.textWhite}>
            <strong>Cấp duyệt tối đa:</strong> {proposal.capDuyet}
          </Text>
        </div>
      </Card>

      {/* Existing modals */}
      <Modal
        open={isModalVisible} 
        onCancel={handleCancel} 
        footer={null} 
        width={1250} 
        style={{ top: 20, overflow: 'hidden', height: '100vh'}} 
      > 
        <MachineryMaterialsConfirm proposal={proposal} type={type} capDuyet={CapDuyet} handleClose={handleCancel} />
      </Modal>
      
      <Modal  
        open={isModalEdit}
        onCancel={()  => {
          dispatch(accountingInvoiceActions.setProposalFormSelected(undefined));
          const random = Utils.generateRandomString(3);
          dispatch(accountingInvoiceActions.setClearData(random));
          showModalEdit(false);
        }}
        footer={null}
        width={1200}
      >
        <NewMachineryMaterialList
          type={type}
          session={Utils.generateRandomString(3)}
          handleClose={() => {
            setIsModalEdit(false);
          }}
        />
      </Modal>

      {/* New Delete Confirmation Modal */}
      <Modal
        open={isDeleteConfirmVisible}
        onCancel={() => setIsDeleteConfirmVisible(false)} // Close modal if user cancels
        onOk={handleDelete} // Proceed with delete if user confirms
        okText="Xác nhận"
        cancelText="Hủy"
        title="Xác nhận xóa"
      >
        <p>Bạn có chắc chắn muốn xóa đề nghị này không?</p>
      </Modal>
    </>
  );
};
type ProposalListProps = {
  type: eTypeVatTuMayMoc;
};

const ProposalList = ({ type }: ProposalListProps) => {
  const { t } = useTranslation();
  const products = useAppSelector(getProducts());
  const danhsachduyetmuahang = useAppSelector(getDanhSachDuyetMuaHang());
  const dateRange = useAppSelector(getDateRange());
  const [ColoredData, setColoredData] = useState<DayDataType[]>([]);
  const [reload, setReload] = useState(false);  // State to trigger re-fetching
  const DanhSachVatTu = useAppSelector(getProducts());
  const DanhSachMayMoc = useAppSelector(getMayMoc());
  const userIIS = useAppSelector(getgetUserIIS());
  const dispatch = useAppDispatch();

  // Function to trigger reload
  const triggerReload = () => {
    setReload(!reload);
  };

  useEffect(() => {
    if (dateRange && dateRange.startDate && dateRange.endDate) {
      dispatch(
        accountingInvoiceActions.GetDanhSachDuyetMuaHang({
          params: {
            madvcs: 'THUCHIEN',
            ngay_de_nghi_tu_ngay: `${dayjs(dateRange?.startDate).format('YYYY-MM-DD')}`,
            ngay_de_nghi_den_ngay: `${dayjs(dateRange?.endDate).format('YYYY-MM-DD')} `,
          },
        }),
      );
    }
  }, [dateRange, reload]);  // Add reload as a dependency to re-fetch data

  useEffect(() => {
    const ListVatTu: any = [];
    if (Array.isArray(danhsachduyetmuahang) && danhsachduyetmuahang.length > 0) {
      const LstMapMa_vt: ProposalData[] = danhsachduyetmuahang.map(it => ({
        ...it,
        ma_vt: it.chiTietDeNghiMuaHang[0].ma_vt,
      }));
  
      if (LstMapMa_vt) {
        LstMapMa_vt.map((m: any) => {
          const product = DanhSachVatTu.find(vt => vt.ma_vt === m.ma_vt);
          const maymoc = DanhSachMayMoc.find(mm => mm.ma_Tscd === m.ma_vt);
          if (product) {
            const dk =
              (type === eTypeVatTuMayMoc.VatTuChinh && product.productType === eTypeVatTu.VatTuChinh) ||
              (type === eTypeVatTuMayMoc.VatTuPhu && product.productType === eTypeVatTu.VatTuPhu);
            if (dk) {
              ListVatTu.push({
                ...m,
                key: Utils.generateRandomString(15),
                checkbox: false,
              });
            }
          }
          if (maymoc && type === eTypeVatTuMayMoc.MayMoc) {
            ListVatTu.push({
              ...m,
              key: Utils.generateRandomString(15),
              checkbox: false,
            });
          }
        });
      }
  
      const sortedListVatTu = ListVatTu.sort((a: ProposalData, b: ProposalData) => {
        const capDuyetChi = userIIS && userIIS[0] ? userIIS[0].capDuyetChi : 0;
        
        // Tính toán cấp duyệt cho mỗi proposal
        const capDuyetA = calculateCapDuyet(a);
        const capDuyetB = calculateCapDuyet(b);
      
        // Nếu capDuyet của người dùng lớn hơn capDuyet của proposal, ưu tiên proposal đó
        if (capDuyetA < capDuyetChi && capDuyetB >= capDuyetChi) return -1;
        if (capDuyetA >= capDuyetChi && capDuyetB < capDuyetChi) return 1;
      
        // Sắp xếp theo cấp duyệt tăng dần
        return capDuyetA - capDuyetB;
      });
  
      const dayDataArray: DayDataType[] = groupByDate(sortedListVatTu);
      setColoredData(dayDataArray);
    } else {
      setColoredData([]);
    }
  }, [danhsachduyetmuahang, type, userIIS]);


  return (
    <div>
      {(Array.isArray(ColoredData) && ColoredData.length > 0) ? (
        <div className={styles.proposalList}>
          {ColoredData.map(dayData => (
            <Card key={dayData.date} className={styles.proposalDayCard}>
              <div className={styles.proposalDayCardTitle}>{`${t(dayData.date)}`}</div>
              <div className={styles.proposalDayCardContent}>
                {dayData.proposals.map(proposal => (
                  <ProposalCard key={proposal.id} proposal={proposal} type={type} onReload={triggerReload} />
                ))}
              </div>
            </Card>
          ))}
        </div>
      ) : (
        <Empty description={t('noProposals')} />
      )}
    </div>
  );
};

export default ProposalList;
/* eslint-disable import/order */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { eStatusRequest, IBaoXuatNhapTonData, PhieuDeNghiMuaHangDTO } from '@/common/define';
import { ImportGood } from '@/pages/MachineryMaterials/components/ImportGoods/ImportGoods';
import { ProposalData } from '@/pages/MachineryMaterials/components/ProposalHistory';
import { ChiTietDeNghiMuaHangDTO, DanhSachBoPhanDTO, GetTonKhoDTO, IBaoCaoXuatNhapTonDTO, MoneyTypeDTO, PhieuDieuChuyenDTO, PhieuNhapXuatKhoDTO, ProductDTO, ProductUnitDTO, ProposalFormDTO, ThietBiDTO, TonKhoDTO, WareHouseDTO } from '@/services/AccountingInvoiceService';

interface AccountingInvoiceState {
  products: ProductDTO[];
  wareHouses: WareHouseDTO[];
  productUnits: ProductUnitDTO[];
  machineries: ThietBiDTO[];
  moneyTypes: MoneyTypeDTO[];
  danhSachDuyetChi: PhieuNhapXuatKhoDTO[];
  danhSachDuyetMuaHang: ProposalData[]; 
  Tonkho: TonKhoDTO[];
  TonKhoTheoNgay: TonKhoDTO[];
  proposalForms: PhieuDeNghiMuaHangDTO[];
  ChiTietHangHoa: ChiTietDeNghiMuaHangDTO[];
  proposalFormSelected?: PhieuDeNghiMuaHangDTO;
  DuyetData?: PhieuDeNghiMuaHangDTO;
  query_danhSachDuyetChi?: any,
  query_danhSachDuyetMuaHang?: any,
  cap_Duyet: any,
  dateRanges: { startDate: string | null,  endDate: string | null} | null; 
  statusRequest?: {api: string, status: eStatusRequest};
  phieuChuyenVatTu: PhieuDieuChuyenDTO[];
  Dieuchuyenvattu:any;
  clearData?: string;
  DanhSachBoPhan?: DanhSachBoPhanDTO[];
  baoCaoXuatNhapTon?: IBaoXuatNhapTonData[];
}

const initialState: AccountingInvoiceState = {
  products: [],
  wareHouses: [],
  productUnits: [],
  machineries: [],
  moneyTypes: [],
  phieuChuyenVatTu: [],
  Dieuchuyenvattu: {},
  danhSachDuyetChi: [],
  danhSachDuyetMuaHang: [],
  Tonkho: [],
  TonKhoTheoNgay: [],
  query_danhSachDuyetChi: undefined,
  query_danhSachDuyetMuaHang: undefined,
  cap_Duyet: 2,
  proposalForms: [],
  dateRanges: { startDate: dayjs().format('YYYY-MM-DD'), endDate: dayjs().format('YYYY-MM-DD') },
  ChiTietHangHoa: [],
  DuyetData: undefined,
};const accountingInvoiceSlice = createSlice({
  name: 'accountingInvoice',
  initialState,
  reducers: {
    // Set
    CreatePhieuDieuChuyen: (state: AccountingInvoiceState, action: PayloadAction<{ data: PhieuDieuChuyenDTO }>) => {},
    CreatePhieuNhapKho: (state: AccountingInvoiceState, action: PayloadAction<{ data: ImportGood }>) => {},
    CreatePhieuXuatKho: (state: AccountingInvoiceState, action: PayloadAction<{ data: PhieuDieuChuyenDTO }>) => {},
    CreatePhieuNhapKhodc: (state: AccountingInvoiceState, action: PayloadAction<{ data: PhieuDieuChuyenDTO }>) => {},
    CreatePhieuDieuChuyenSuccess: (state: AccountingInvoiceState, action) => {
      state.phieuChuyenVatTu = action.payload;
    },
    GetDieuChuyenVatTu: (state: AccountingInvoiceState, action: PayloadAction<{ madvcs: string,tu_ngay:string,den_ngay:string,ma_kho:string}>) => {},
    setDieuchuyenvattu: (state: AccountingInvoiceState, action: any) => {
      state.Dieuchuyenvattu = action.payload;
    },
    setClearData: (state: AccountingInvoiceState, action: PayloadAction<string>) => {
      state.clearData = action.payload;
    },
    SetProducts: (state: AccountingInvoiceState, action: any) => {
      state.products = action.payload;
    },
    SetDanhSachBoPhan: (state: AccountingInvoiceState, action: any) => {
      state.DanhSachBoPhan = action.payload;
    },
    SetWareHouse: (state: AccountingInvoiceState, action: any) => {
      state.wareHouses = action.payload;
    },
    setProductUnits: (state: AccountingInvoiceState, action: any) => {
      state.productUnits = action.payload;
    },
    setMachineries: (state: AccountingInvoiceState, action: any) => {
      state.machineries = action.payload;
    },
    setMoneyTypes: (state: AccountingInvoiceState, action: any) => {
      state.moneyTypes = action.payload;
    },
    setDanhSachDuyetChi: (state: AccountingInvoiceState, action: any) => {
      state.danhSachDuyetChi = action.payload;
    },
    setDanhSachDuyetMuaHang: (state: AccountingInvoiceState, action: any) => {
      state.danhSachDuyetMuaHang = action.payload;
    },
    setDuyetMuaHang: (state: AccountingInvoiceState, action: any) => {
      state.DuyetData = action.payload;
    },
    setTonkho: (state: AccountingInvoiceState, action: any) => {
      state.Tonkho = action.payload;
    },
    setTonKhoTheoNgay: (state: AccountingInvoiceState, action: any) => {
      state.TonKhoTheoNgay = action.payload;
    },
    setProposalForms: (state: AccountingInvoiceState, action: any) => {
      state.proposalForms = action.payload;
    },
    setChiTietHangHoa: (state: AccountingInvoiceState, action: any) => {
      state.ChiTietHangHoa = action.payload;
      console.log('action.payload', action.payload);
    },
    setProposalFormSelected: (state: AccountingInvoiceState, action: any) => {
      state.proposalFormSelected = action.payload;
    },
    // Get  
    GetProducts: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ params: { productCode?: string; productName?: string } }>,
    ) => {},
    GetDanhSachBoPhan: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ params: { productCode?: string; productName?: string } }>,
    ) => {},
    GetWareHouse: (state: AccountingInvoiceState, action: PayloadAction<{ params: any }>) => {},
    GetProductUnit: (state: AccountingInvoiceState, action: PayloadAction<{ params: any }>) => {},
    GetDanhSachThietBi: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ params: { ma_tscd?: string; ten_tscd?: string; otherFilter?: string } }>,
    ) => {},
    GetMoneyTypeList: (state: AccountingInvoiceState, action: PayloadAction<{ params: any }>) => {},
    GetProposalForm: (
      state: AccountingInvoiceState,
      action: PayloadAction<{
        params: { madvcs: string; ngay_de_nghi_tu_ngay: string; ngay_de_nghi_den_ngay: string; ma_kho: string };
      }>,
    ) => {},
    GetDanhSachPhieuDeNghiMuaHang_ChiTietHangHoa: (
      state: AccountingInvoiceState,
      action: PayloadAction<{
        params: {  recId:  number};
      }>, 
    ) => {},
    GetDanhSachDuyetChi: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ params: { CapDuyet: string; madvcs: string; tu_ngay: string; den_ngay: string } }>,
    ) => {},
    GetDanhSachDuyetMuaHang: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ params: { madvcs: string; ngay_de_nghi_tu_ngay: string, ngay_de_nghi_den_ngay: string,  maKho?: string    } }>,
    ) => {},
    GetTonKho: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ data: GetTonKhoDTO; params: any; TonKhoTheoNgay?: boolean }>,
    ) => {},
    DuyetChi: (state: AccountingInvoiceState, action: PayloadAction<{ data: number[]; params: any }>) => {},
    HuyDuyetChi: (state: AccountingInvoiceState, action: PayloadAction<{ data: number[]; params: any }>) => {},
    // Create
    CreatePhieuNhapXuatKho: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ data: PhieuNhapXuatKhoDTO; params: any }>,
    ) => {},
    ConfirmProposalForm: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ data: PhieuDeNghiMuaHangDTO; params: any }>,
    ) => {},
    CreateProposalForm: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ data: PhieuDeNghiMuaHangDTO; params: any }>,
    ) => {},
    // Delete
    DeletePhieuNhapXuatKho: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ ids: number[]; params: any }>,
    ) => {},
    DeleteProposalForm: (state: AccountingInvoiceState, action: PayloadAction<{ ids: string[]; params: any }>) => {},
    DeletePhieuDeNghiMuaHang: (state: AccountingInvoiceState, action: PayloadAction<{ ids:string[]; params: any }>) => {},
    // Update
    UpdateProposalForm: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ id: number; data: ProposalFormDTO; params: any }>,
    ) => {},
    // Query
    setQuery_danhSachDuyetChi: (
      state: AccountingInvoiceState,
      action: PayloadAction<{
        params: { CapDuyet: string; madvcs: string; tu_ngay: string; den_ngay: string } | undefined;
      }>,
    ) => {
      state.query_danhSachDuyetChi = action.payload;
    },
    setQuery_danhSachDuyetMuaHang: (
      state: AccountingInvoiceState,
      action: PayloadAction<{ params: { madvcs: string; ngay_de_nghi_tu_ngay: string; ngay_de_nghi_den_ngay: string } | undefined }>,
    ) => {
      state.query_danhSachDuyetMuaHang = action.payload;
    },
    setCapDuyet: (state: AccountingInvoiceState, action: PayloadAction<{ params: any }>) => {
      state.cap_Duyet = action.payload;
    },
    setDateRange: (state: AccountingInvoiceState, action: PayloadAction<any>) => {
      state.dateRanges = action.payload;
    },
    setStatusRequest: (state: AccountingInvoiceState, action: PayloadAction<any>) => {
      state.statusRequest = action.payload;
    },
    getBaoCaoXuatNhapTon: (state: AccountingInvoiceState, action: PayloadAction<{ params: { data: IBaoCaoXuatNhapTonDTO } }>) => {},
    setBaoCaoXuatNhapTon: (state: AccountingInvoiceState, action: any) => {
      state.baoCaoXuatNhapTon = action.payload;
    },
    splitDeNghiMuaHangTheoNhaCungCap: (state: AccountingInvoiceState, action: PayloadAction<{params: {guid: string}}>) => {    },
  },
});

export const accountingInvoiceActions = accountingInvoiceSlice.actions;
export const accountingInvoiceReducer = accountingInvoiceSlice.reducer;

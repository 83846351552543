/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import { Table, Checkbox, PaginationProps, Empty, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';
import { ColumnType, TableProps } from 'antd/es/table';
import classnames from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import styles from './MachineryMaterialsList.module.css';
import {
  accountingInvoice,
  defaultPagingParams,
  eTrackerCode,
  eTypeVatTu,
  eTypeVatTuMayMoc,
  FormatDateAPI,
  MachineryDimDTO,
  MaterialsDim,
  MaterialsDimDTO,
  VatTuMayMocDTO,
} from '@/common/define';
import { useWindowSize } from '@/hooks';
import { ProductDTO, ThietBiDTO, TonKhoDTO } from '@/services/AccountingInvoiceService';
import { accountingInvoiceActions, getMayMoc, getProducts, getTonkho, getWareHouses } from '@/store/accountingInvoice';
import { getCurrentCompany } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getMachineries,
  getMaterials,
  issueActions,
  getIssueByVersion,
  getTagsVersion,
  getTracker,
  queryParamsByTagVersion,
  queryParamsMaterial,
} from '@/store/issue';
import { getLoading } from '@/store/loading';
import { getSelectedProject, projectActions } from '@/store/project';
import { RootState } from '@/store/types';
import Utils from '@/utils';

interface MachineryMaterialsListProps {
  type: eTypeVatTuMayMoc;
}
const MachineryMaterialsList = (props: MachineryMaterialsListProps) => {
  const { type } = props;
  const [dataSource, setDataSource] = useState<VatTuMayMocDTO[]>([]);
  const [daySelected, setDaySelected] = useState<Dayjs>(dayjs());
  const { t } = useTranslation('material');
  const dispatch = useAppDispatch();
  const trackers = useAppSelector(getTracker());
  const material = useAppSelector(getMaterials());
  const machinery = useAppSelector(getMachineries());
  const DanhSachVatTu = useAppSelector(getProducts());
  const DanhSachMayMoc = useAppSelector(getMayMoc());
  const paramsMaterial = useAppSelector(queryParamsMaterial());
  const isLoading = useAppSelector(getLoading(MaterialsDim.getMaterialsDim));
  const Tonkho = useAppSelector((state: RootState) => state.accountingInvoice.Tonkho);
  const company = useAppSelector(getCurrentCompany());
  const windowSize = useWindowSize();
  const selectedProject = useAppSelector(getSelectedProject());
  const wareHouses = useAppSelector(getWareHouses());
  const projectwareHouses = useAppSelector((state: RootState) => state.project.projectwarehouseResponse);
  const isLoadingTonKho = useAppSelector(getLoading(accountingInvoice.GetTonKho));

  useEffect(() => {
    if (selectedProject) {
      // Dispatch action để lấy danh sách warehouses
      dispatch(accountingInvoiceActions.GetDanhSachBoPhan({params: {}}));
      dispatch(
        projectActions.getWarehousesRequest({
          projectId: selectedProject?.id,
        }),
      );
    } else {
      dispatch(
        accountingInvoiceActions.GetTonKho({
          data: {
            madvcs: 'THUCHIEN',
            danhSachMaHang: [],
            ngay_kiem_tra: dayjs().format(FormatDateAPI),
            danhSachMakho: ['TONGTEST', 'TONGTEST CCDC'],
          },
          params: {},
        }),
      );
    }
  }, [selectedProject]);

  useEffect(() => {
    // Khi projectwareHouses được cập nhật, gọi GetTonKho
    if (!projectActions) return;
    if (projectwareHouses && projectwareHouses.length > 0) {
      GetTonKho();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectwareHouses]);
  useEffect(() => {
    GetTonKho(); // Gọi lại API GetTonKho khi type thay đổi
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);
  const GetTonKho = () => {
    const danhSachMakho: string[] = [];
    let hasMatchingWarehouseCode = false;
    if (projectwareHouses) {
      projectwareHouses.forEach(warehouse => {
        if (type === eTypeVatTuMayMoc.VatTuChinh ) {
          if (!warehouse.warehouseCode.endsWith('CCDC')) {
            danhSachMakho.push(warehouse.warehouseCode);
            hasMatchingWarehouseCode = true;
          }
        }
        if (type === eTypeVatTuMayMoc.VatTuPhu) {
          if (!warehouse.warehouseCode.endsWith('CCDC')) {
            danhSachMakho.push(warehouse.warehouseCode);
            hasMatchingWarehouseCode = true;
          }
        }else if (type === eTypeVatTuMayMoc.MayMoc) {
          if (warehouse.warehouseCode.endsWith('CCDC')) {
            danhSachMakho.push(warehouse.warehouseCode);
            hasMatchingWarehouseCode = true;
          }
        }
      });
    }
    if (!hasMatchingWarehouseCode) {
      return;
    }
    if (selectedProject) {
      dispatch(
        accountingInvoiceActions.GetTonKho({
          data: {
            madvcs: 'THUCHIEN',
            danhSachMaHang: [],
            ngay_kiem_tra: dayjs().format(FormatDateAPI),
            danhSachMakho,
          },
          params: {},
        }),
      );
    } else {
      if (type === eTypeVatTuMayMoc.VatTuChinh) {
        dispatch(
          accountingInvoiceActions.GetTonKho({
            data: {
              madvcs: 'THUCHIEN',
              danhSachMaHang: [],
              ngay_kiem_tra: dayjs().format(FormatDateAPI),
              danhSachMakho: ['TONGTEST'],
            },
            params: {},
          }),
        );
      } else {
        dispatch(
          accountingInvoiceActions.GetTonKho({
            data: {
              madvcs: 'THUCHIEN',
              danhSachMaHang: [],
              ngay_kiem_tra: dayjs().format(FormatDateAPI),
              danhSachMakho: ['TONGTEST CCDC'],
            },
            params: {},
          }),
        );
      }
    }
  };
  const getMaterialsData = (search?: any) => {
    if (trackers) {
      let trackerId = Utils.getTrackerID(trackers, eTrackerCode.GiaoViecTheoNgay);
      if (trackerId >= 0) {
        dispatch(
          issueActions.getMaterialsDimByTracker({
            trackerId,
            params: { ...search, type: 1, pageSize: 50, paging: false },
          }),
        );
        dispatch(
          issueActions.getMachinerysDimByTracker({
            trackerId,
            params: { ...search, type: 1, pageSize: 50, paging: false },
          }),
        );
      }
    }
  };

  const getDataFromTonkho = () => {
    const data: VatTuMayMocDTO[] = [];
    if (Tonkho) {
      Tonkho.map((m: TonKhoDTO) => {
        const product = DanhSachVatTu.find(vt => vt.ma_vt === m.ma_vt); // Tìm vật tư theo mã
        if (product) {
          const dk =
            (type === eTypeVatTuMayMoc.VatTuChinh && product.productType === eTypeVatTu.VatTuChinh) ||
            (type === eTypeVatTuMayMoc.VatTuPhu && product.productType === eTypeVatTu.VatTuPhu);
          if (dk) {
            // Kiểm tra điều kiện dk
            data.push({
              key: Utils.generateRandomString(15),
              id: product.id,
              ma: m.ma_vt,
              name: product.ten_vt, // Lấy tên vật tư
              unitOfMeasure: product.dvt, // Lấy đơn vị tính
              kldinhmuc: '',
              tongdacap: '',
              dexuat: '',
              tonkho: m.luong_ton.toString(),
              checkbox: false,
            });
          }
        }
      }).filter(Boolean);
      setDataSource(data);
    } else {
      setDataSource([])
    }
  };

  const getDataFromTonkhoMayMoc = () => {
    const data: VatTuMayMocDTO[] = [];
    if (Tonkho) {
      Tonkho.forEach((m: TonKhoDTO) => {
        const danhSachMayMoc = DanhSachMayMoc.find(vt => vt.ma_Tscd === m.ma_vt); // Tìm vật tư theo mã
        if (danhSachMayMoc) {
          // Kiểm tra điều kiện dk
          data.push({
            key: '',
            id: danhSachMayMoc.id,
            ma: danhSachMayMoc.ma_Tscd,
            name: danhSachMayMoc.ten_Tscd, // Lấy tên vật tư
            unitOfMeasure: danhSachMayMoc.ccdc_Dvt, // Lấy đơn vị tính
            soluonghienco: danhSachMayMoc.ccdc_So_Luong.toString(),
            vitri: '',
            checkbox: false,
          });
        }
      });
      setDataSource(data);
    }
  };
  useEffect(() => {
    // console.log('type ', type);
    // console.log('material ', material);
    // console.log('machinery ', machinery);
    switch (type) {
      case eTypeVatTuMayMoc.VatTuChinh:
      case eTypeVatTuMayMoc.VatTuPhu:
        getDataFromTonkho(); // Gọi hàm mới để lấy dữ liệu
        break;
      case eTypeVatTuMayMoc.MayMoc:
        getDataFromTonkhoMayMoc();
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DanhSachVatTu, DanhSachMayMoc, Tonkho, type]);

  const handelData = (setDataSource: any, checked: boolean, key?: string | number) => {
    setDataSource((prevDataSource: any) => {
      const newDataSource = [...prevDataSource];
      for (let i = 0; i < newDataSource.length; i++) {
        if (key) {
          if (newDataSource[i].key === key) {
            newDataSource[i].checkbox = checked;
          }
        } else newDataSource[i].checkbox = checked;
      }
      return newDataSource;
    });
  };

  const handleSelectAll = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    switch (type) {
      case eTypeVatTuMayMoc.VatTuChinh:
      case eTypeVatTuMayMoc.VatTuPhu:
        handelData(setDataSource, checked);
        break;
      case eTypeVatTuMayMoc.MayMoc:
        handelData(setDataSource, checked);
        break;
    }
  };

  const handleCheckboxChange = (key: string | number, checked: boolean) => {
    switch (type) {
      case eTypeVatTuMayMoc.VatTuChinh:
      case eTypeVatTuMayMoc.VatTuPhu:
        handelData(setDataSource, checked, key);
        break;
      case eTypeVatTuMayMoc.MayMoc:
        handelData(setDataSource, checked, key);
        break;
    }
  };

  const rowClassName = (record: VatTuMayMocDTO | VatTuMayMocDTO) =>
    classnames({ [styles.selectedRow]: record.checkbox });

  //#region columnsVatu
  const columnsVatu: ColumnType<VatTuMayMocDTO>[] = [
    {
      title: <span className={styles.tableHeader}>{t('Material code')}</span>,
      dataIndex: 'ma',
      key: 'ma',
      width: 116,
      className: styles.tablecell,
      render: (text: string) => (
        <span className={['BT01', 'Thep1', 'Thep2', 'Thep10', 'Thep12'].includes(text) ? styles.underlineText : ''}>
          {text}
        </span>
      ),
      // align: 'center',
    },
    {
      title: (
        <span className={styles.tableHeader}>
          {type === eTypeVatTuMayMoc.VatTuChinh ? t('Main material name') : t('Name of auxiliary material')}
        </span>
      ),
      dataIndex: 'name',
      key: 'name',
      width: 163,
      className: styles.tablecell,
      align: 'start',
    },
    {
      title: <span className={styles.tableHeader}>{t('Unit')}</span>,
      dataIndex: 'unitOfMeasure',
      key: 'unitOfMeasure',
      width: 93,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Standard Volume')}</span>,
      dataIndex: 'kldinhmuc',
      key: 'kldinhmuc',
      width: 175,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Total issued')}</span>,
      dataIndex: 'tongdacap',
      key: 'tongdacap',
      width: 137,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Also recommended')}</span>,
      dataIndex: 'dexuat',
      key: 'dexuat',
      width: 175,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Actual inventory')}</span>,
      dataIndex: 'tonkho',
      key: 'tonkho',
      width: 160,
      className: styles.tablecell,
      align: 'center',
    },
  ];

  //#region columnsMayMoc
  const columnsMayMoc: ColumnType<VatTuMayMocDTO>[] = [
    {
      title: t('Material code'),
      dataIndex: 'ma',
      key: 'ma',
      width: 142,
      className: styles.tablecell,
      render: (text: string) => (
        <span className={['BT01', 'Thep1', 'Thep2', 'Thep10', 'Thep12'].includes(text) ? styles.underlineText : ''}>
          {text}
        </span>
      ),
      // align: 'center',
    },
    {
      title: t('Machine name'),
      dataIndex: 'name',
      key: 'name',
      width: 520,
      className: styles.tablecell,
    },
    {
      title: t('Unit'),
      dataIndex: 'unitOfMeasure',
      key: 'unitOfMeasure',
      width: 93,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: t('Quantity available'),
      dataIndex: 'soluonghienco',
      key: 'soluonghienco',
      width: 168,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: t('Current position'),
      dataIndex: 'vitri',
      key: 'vitri',
      width: 155,
      className: styles.tablecell,
      align: 'center',
    },
  ];

  const handleTableChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const search = { ...paramsMaterial, page: current };
    getMaterialsData(search);
  };

  const showTotal: PaginationProps['showTotal'] = (total, range) =>
    t('Paging total', { range1: range[0], range2: range[1], total });

  return (
    <div className="MaterialList">
      <header className="MaterialList-header">
        {dataSource.length === 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 150px)',
              backgroundColor: 'white',
              margin: 10,
            }}
          >
            <Empty
              description={
                <>
                  <Typography.Title level={4}>{t('No data found based on filtering criteria')}</Typography.Title>
                </>
              }
            />
          </div>
        )}
        {dataSource && dataSource.length > 0 && (
          <Table
            dataSource={dataSource}
            rowKey={r => r.ma}
            columns={type === eTypeVatTuMayMoc.MayMoc ? columnsMayMoc : columnsVatu}
            bordered
            size="middle"
            style={{ width: '100%', height: '75vh' }}
            rowClassName={rowClassName}
            scroll={{ x: 1000, y: windowSize[1] - 170 }}
            pagination={false}
            // pagination={{
            //   current: paramsMaterial?.page || defaultPagingParams.page,
            //   pageSize: paramsMaterial?.pageSize > 9999 ? defaultPagingParams.pageSize : paramsMaterial?.pageSize,
            //   total: material?.queryCount || 0,
            //   responsive: true,
            //   showTotal,
            //   showSizeChanger: true,
            // }}
            loading={isLoading || isLoadingTonKho}
            onChange={handleTableChange}
          />
        )}
      </header>
    </div>
  );
};

export default MachineryMaterialsList;

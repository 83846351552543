/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, InputNumber, Select, Space, Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import {
  accountingInvoice,
  AutoCompleteOptions,
  eStatusRequest,
  eTypeVatTu,
  eTypeVatTuMayMoc,
  FormatDateAPI,
  madvcs,
  PhieuDeNghiMuaHangDTO,
  ProposalType,
} from '@/common/define';
import Confirm from '@/pages/Components/Confirm/Confirm';
import { ChiTietDeNghiMuaHangDTO, ProposalFormDTO, VatTuMayMocDeXuatDTO } from '@/services/AccountingInvoiceService';
import {
  accountingInvoiceActions,
  getChiTietHangHoa,
  getClearData,
  getDanhSachBoPhan,
  getMayMoc,
  getProducts,
  getProposalFormSelected,
  getStatusRequest,
  getTonkho,
  getTonKhoTheoNgay,
  getWareHouses,
} from '@/store/accountingInvoice';
import { getCurrentCompany, getCurrentUser } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getCategorys } from '@/store/issue';
import { getEmployeesByCompanyId, getSelectedProject, projectActions } from '@/store/project';
import { RootState } from '@/store/types';
import Utils from '@/utils';
import AutoCompleteCustom from '../AutoCompleteCustom';
import styles from './NewMachineryMaterialList.module.css';
import { getLoading } from '@/store/loading';

enum eDataFieldName {
  key,
  ma,
  ten,
  donvi,
  klkehoach,
  tonkho,
  klconlai,
  dexuat,
  ngaynhap,
  ghichu,
  maKho,
  tenKho,
  Mavv,
  DienGiai,
  gia1,
  ncc1,
  gia2,
  ncc2,
  gia3,
  ncc3,
  isRowFuction,
}

interface IDataModifying {
  dexuat?: number;
  ghichu?: string;
  maKho?: string;
  tenKho?: string;
  Mavv?: string;
  DienGiai?: string;
  gia1?: string;
  gia2?: string;
  gia3?: string;
  ncc1?: string;
  ncc2?: string;
  ncc3?: string;
  guid?: string;
}
let dataModifying: {
  [key: string]: IDataModifying;
} = {};

let optionsName: AutoCompleteOptions[] = [];
let optionsCode: AutoCompleteOptions[] = [];
let currentScreen: eTypeVatTuMayMoc = eTypeVatTuMayMoc.VatTuChinh;
const NewMachineryMaterialList: React.FC<{
  type: eTypeVatTuMayMoc;
  session?: string;
  handleClose: () => void;
}> = ({ type, session, handleClose }) => {
  const dispatch = useAppDispatch();
  const producsts = useAppSelector(getProducts());
  const tonKhoTheoNgay = useAppSelector(getTonKhoTheoNgay());
  const tonKho = useAppSelector(getTonkho());
  const machineries = useAppSelector(getMayMoc());
  const proposalFormSelected = useAppSelector(getProposalFormSelected());
  // const [optionsName, setOptionsName] = useState<AutoCompleteOptions[]>([]);
  // const [optionsCode, setOptionsCode] = useState<AutoCompleteOptions[]>([]);
  const company = useAppSelector(getCurrentCompany());
  const wareHouses = useAppSelector(getWareHouses());
  const selectedProject = useAppSelector(getSelectedProject());
  const ChiTietHangHoa = useAppSelector(getChiTietHangHoa());
  const statusRequest = useAppSelector(getStatusRequest());
  const user = useAppSelector(getCurrentUser());
  const clearData = useAppSelector(getClearData());
  const danhSachBoPhan = useAppSelector(getDanhSachBoPhan());
  const projectwareHouses = useAppSelector((state: RootState) => state.project.projectwarehouseResponse);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [removeId, setRemoveId] = useState<string>();
  const isLoading = useAppSelector(getLoading(accountingInvoice.GetTonKho));
  // const isLoading = true;

  const summary: ProposalType = {
    key: 'summary',
    ma: '',
    ten: 'Tổng',
    donvi: '',
    klkehoach: '',
    tonkho: '',
    klconlai: '',
    dexuat: '0',
    ngaynhap: '',
    ghichu: '',
    isRowFuction: true,
    maKho: '',
    tenKho: '',
    Mavv: '',
    DienGiai: '',
    capDuyet: 0,
  };
  const [dataSource, setDataSource] = useState<ProposalType[]>([]);
  const [daySelected, setDaySelected] = useState<Dayjs>(dayjs());
  const [GoodsReceiptDate, setGoodsReceiptDate] = useState<Dayjs>(dayjs());
  const [dienGiai, setDienGiai] = useState<string>();
  const [department, setDepartment] = useState<string>('');
  const [maPhieu, setMaPhieu] = useState<string>('DX2509_2');
  const [category, setCategory] = useState<any>();
  const categorys = useAppSelector(getCategorys());
  const tCategory = useTranslation('category').t;
  const [level, setlevel] = useState([0, 1, 2, 3, 4, 5]);
  const [capDuyet, setcapDuyet] = useState<number>(1);
  const employeesByCompanyId = useAppSelector(getEmployeesByCompanyId());
  const [proponent, setProponent] = useState<string>(Utils.getFullName(user));
  const [userData, setUserData] = useState<{ email: string; phone: string; id: string; userName: string }>({
    email: user.Email,
    phone: user.PhoneNumber,
    id: user.Id,
    userName: user.UserName,
  });
  const [notification, setNotification] = useState<string>('');
  useEffect(() => {
    if (company) {
      dispatch(projectActions.getEmployeesByCompanyIdRequest(company.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  useEffect(() => {
    console.log('danhSachBoPhan ', danhSachBoPhan);
  }, [danhSachBoPhan]);
  useEffect(() => {
    console.log('projectwareHouses ', projectwareHouses);
    GetTonKho([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectwareHouses]);
  
  useEffect(() => {
    console.log('useEffect clearData ', clearData);
    resetDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearData]);

  const resetDataSource = () => {
    dataModifying = {};
    setDataSource((prevDataSource: any) => {
      return [];
    });
    setcapDuyet(0);
    setDienGiai('');
    setDaySelected(dayjs());
    setDepartment('');
  };

  useEffect(() => {
    if (
      statusRequest &&
      statusRequest.api === accountingInvoice.CreateProposalForm &&
      statusRequest.status === eStatusRequest.success
    ) {
      resetDataSource();
      handleClose();
      dispatch(accountingInvoiceActions.setStatusRequest(undefined));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusRequest]);

  useEffect(() => {
    // resetDataSource();
    // setcapDuyet(1);
    // setDienGiai('');
    dispatch(accountingInvoiceActions.GetDanhSachBoPhan({params: {}}));
    if (selectedProject) {
      dispatch(
        projectActions.getWarehousesRequest({
          projectId: selectedProject.id,
        }),
      );
    } else {
      dispatch(projectActions.setprojectwarehouse([]));
    }
    console.log('type ', type);
    // console.log('selectedProject ', selectedProject);
    currentScreen = type;
    GetTonKho([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  useEffect(() => {
    // console.log('category ', category);
  }, [category]);

  useEffect(() => {
    if (proposalFormSelected) {
      convertPhieuDeXuat(proposalFormSelected);
    } else {
      resetDataSource();
    }
    console.log('proposalFormSelected ', proposalFormSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposalFormSelected]);

  const tinhKLConlai = (klDinhMuc: number, tonkho: number, dexuat: number) => {
    return klDinhMuc - tonkho - dexuat;
    // return tonkho - dexuat;
  }

  const createOptionsVatTu = (loaiVT: eTypeVatTu) => {
    const oName: AutoCompleteOptions[] = [];
    const oCode: AutoCompleteOptions[] = [];
    if (producsts && producsts.length) {
      producsts.forEach(p => {
        if (p.productType === loaiVT) {
          oName.push({
            label: p.ten_vt,
            value: p.ma_vt,
          });
          oCode.push({
            label: p.ma_vt,
            value: p.ma_vt,
          });
        }
      });
    }
    // setOptionsName(oName);
    // setOptionsCode(oCode);
    optionsName = oName;
    optionsCode = oCode;
  };

  const createOptionsMayMoc = () => {
    const oName: AutoCompleteOptions[] = [];
    const oCode: AutoCompleteOptions[] = [];
    if (machineries && machineries.length) {
      machineries.forEach(p => {
        oName.push({
          label: p.ten_Tscd,
          value: p.ma_Tscd,
        });
        oCode.push({
          label: p.ma_Tscd,
          value: p.ma_Tscd,
        });
      });
    }
    // setOptionsName(oName);
    // setOptionsCode(oCode);
    optionsName = oName;
    optionsCode = oCode;
  };

  useEffect(() => {
    let reset = false;
    switch (currentScreen) {
      case eTypeVatTuMayMoc.VatTuChinh:
        if (producsts && producsts.length) {
          createOptionsVatTu(eTypeVatTu.VatTuChinh);
          reset = true;
        }
        break;
      case eTypeVatTuMayMoc.VatTuPhu:
        if (producsts && producsts.length) {
          createOptionsVatTu(eTypeVatTu.VatTuPhu);
          reset = true;
        }
        break;
      case eTypeVatTuMayMoc.MayMoc:
        if (machineries && machineries.length) {
          createOptionsMayMoc();
          reset = true;
        }
        break;
    }
    if (selectedProject) {
      dispatch(
        projectActions.getWarehousesRequest({
          projectId: selectedProject.id,
        }),
      );
    }
    currentScreen = type;
    // reset && resetDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [producsts, machineries, type]);

  useEffect(() => {
    // console.log('optionsName ', optionsName);
  }, [optionsName]);

  useEffect(() => {
    // console.log('dienGiai ', dienGiai);
    GenerateOutgoingData();
    // console.log('dataSource ', dataSource);
    // console.log('dataModifying ', dataModifying);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, dienGiai]);

  useEffect(() => {
    if (tonKhoTheoNgay && tonKhoTheoNgay.length) {
      // console.log('tonKhoTheoNgay', tonKhoTheoNgay);
      setDataSource((prevDataSource: any) => {
        const newDataSource = [...prevDataSource];
        for (let i = 0; i < newDataSource.length; i++) {
          if (!newDataSource[i].isRowFuction) {
            let code = getData(newDataSource[i].ma);
            let kl_theo_ke_hoach = getData(newDataSource[i].kl_theo_ke_hoach);
            const tk_kehoach = tonKhoTheoNgay.find(t => t.ma_vt === code);
            const luong_nhap = tk_kehoach?.luong_nhap || 0;
            const luong_xuat = tk_kehoach?.luong_xuat || 0;
            newDataSource[i].klkehoach = <Input value={luong_xuat} readOnly />;
            // newDataSource[i].tonkho = <Input value={tonkho} readOnly />;
            // newDataSource[i].klconlai = <Input value={tonkho} readOnly />;
          }
        }
        return newDataSource;
      });
    }
  }, [tonKhoTheoNgay]);

  useEffect(() => {
    if (tonKhoTheoNgay && tonKhoTheoNgay.length) {
      // console.log('tonKhoTheoNgay', tonKhoTheoNgay);
      setDataSource((prevDataSource: any) => {
        const newDataSource = [...prevDataSource];
        for (let i = 0; i < newDataSource.length; i++) {
          const m = getData(newDataSource[i].ma);
          if (m) {
            const tk_thuchien = tonKho.find( t => t.ma_vt === m);
            let luongTon = tk_thuchien ? tk_thuchien.luong_ton : 0;
            let dx = dataModifying[newDataSource[i].key]?.dexuat || 0;
            const vt = proposalFormSelected?.chiTietDeNghiMuaHang?.find(ct=> ct.ma_vt === m);
            if (vt) {
              luongTon = vt.ton_kho;
              dx = vt.so_luong_yeu_cau;
            }
            newDataSource[i].tonkho = renderInput(true, luongTon.toString(), newDataSource[i].key, eDataFieldName.tonkho);
            newDataSource[i].klconlai = renderInput(true, tinhKLConlai(0, luongTon, dx).toString(), newDataSource[i].key, eDataFieldName.klconlai);
          }
        }
        return newDataSource;
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tonKho]);

  const getData = (data: string | JSX.Element) => {
    if (typeof data === 'string') {
      return data;
    } else {
      return data?.props.value;
    }
  };

  const GenerateOutgoingData = () => {
    // console.log('dataModifying ', dataModifying);
    const data: ProposalFormDTO = {
      ma_phieu: maPhieu || '',
      dien_giai: dienGiai || '',
      hang_muc: category,
      ngay_tao: daySelected.format(FormatDateAPI),
      vat_tu_may_moc: [],
      capDuyet: capDuyet || 1,
      ngay_hoa_don: GoodsReceiptDate ? GoodsReceiptDate.format(FormatDateAPI) : dayjs().format(FormatDateAPI),
    };
    dataSource.forEach((d: ProposalType) => {
      const ma = getData(d.ma);
      if (!d.isRowFuction && ma !== '') {
        const itemMod = dataModifying[d.key];
        const item: VatTuMayMocDeXuatDTO = {
          ma,
          ten: getData(d.ten),
          dvt: getData(d.donvi),
          kl_theo_ke_hoach: getData(d.klkehoach),
          ton_kho_thuc_te: getData(d.tonkho),
          kl_con_lai: getData(d.klconlai),
          de_xuat_lan_nay: itemMod?.dexuat || 0,
          // ngay_yeu_cau_nhap_ve: getData(d.ngaynhap),
          ghi_chu: itemMod?.ghichu || '',
          maKho: itemMod?.maKho || '',
          tenKho: itemMod?.tenKho || '',
          Mavv: itemMod?.Mavv || '',
          DienGiai: itemMod?.DienGiai || '',
          gia1: itemMod?.gia1 || '0',
          gia2: itemMod?.gia2 || '0',
          gia3: itemMod?.gia3 || '0',
          nhaCungCap1: itemMod?.ncc1 || '',
          nhaCungCap2: itemMod?.ncc2 || '',
          nhaCungCap3: itemMod?.ncc3 || '',
        };
        if (itemMod && itemMod.guid) {
          item.guid = itemMod.guid;
        }
        data.vat_tu_may_moc.push(item);
      }
    });
    // console.log('GenerateOutgoingData ', data);
    return data;
  };
  const [newRows, setNewRows] = useState<Set<string>>(new Set());
  const { t } = useTranslation('material');
  const tCommon = useTranslation('common').t;

  const getDonViTinh = (code: string) => {
    switch (currentScreen) {
      case eTypeVatTuMayMoc.VatTuChinh:
        {
          const pr = producsts.find(v => v.ma_vt === code && v.productType === eTypeVatTu.VatTuChinh);
          if (pr) return pr.dvt;
        }
        break;
      case eTypeVatTuMayMoc.VatTuPhu:
        {
          const pr = producsts.find(v => v.ma_vt === code && v.productType === eTypeVatTu.VatTuPhu);
          if (pr) return pr.dvt;
        }
        break;
      case eTypeVatTuMayMoc.MayMoc:
        {
          const pr = machineries.find(v => v.ma_Tscd === code);
          if (pr) return pr.ccdc_Dvt;
        }
        break;
    }
    return '';
  };

  useEffect(() => {
    GetTonKho([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daySelected]);

  const GetDanhSachMaKho = () => {
    const danhSachMakho: string[] = [];
    if (projectwareHouses) {
      switch (currentScreen) {
        case eTypeVatTuMayMoc.VatTuChinh:
        case eTypeVatTuMayMoc.VatTuPhu:
          const khoVT = projectwareHouses.filter(w => !w.warehouseCode.includes('CCDC')).map(w => w.warehouseCode);
          danhSachMakho.push(...khoVT);
          break;
        case eTypeVatTuMayMoc.MayMoc:
          const khoCCDC = projectwareHouses.filter(w => w.warehouseCode.includes('CCDC')).map(w => w.warehouseCode);
          danhSachMakho.push(...khoCCDC);
          break;
      }
      if (projectwareHouses?.length === 1 && danhSachMakho.length === 0) {
        danhSachMakho.push(projectwareHouses[0].warehouseCode);
      }
    }
    if (!selectedProject) {
      switch (currentScreen) {
        case eTypeVatTuMayMoc.VatTuChinh:
        case eTypeVatTuMayMoc.VatTuPhu:
          danhSachMakho.push('TONGTEST');
          break;
        case eTypeVatTuMayMoc.MayMoc:
          danhSachMakho.push('TONGTEST');
          break;
      }
    }
    return danhSachMakho;
  };

  const GetTonKho = (_codes: string[]) => {
    const codes = [..._codes];

    // Add codes from dataSource
    dataSource.forEach(d => {
      const code = getData(d.ma);
      if (code) codes.push(code);
    });
    const danhSachMakho: string[] = GetDanhSachMaKho();
    dispatch(
      accountingInvoiceActions.GetTonKho({
        data: {
          madvcs: madvcs.KEHOACH,
          danhSachMaHang: codes,
          ngay_kiem_tra: daySelected.format(FormatDateAPI),
          danhSachMakho,
        },
        params: {},
        TonKhoTheoNgay: true
      }),
    );
  };

  const handelSelect = (id: string, data: string, type: eDataFieldName, key: string) => {
    const name = optionsName.find(i => i.value === data);
    let codes: string[] = [];
    switch (type) {
      case eDataFieldName.ma:
        if (name) codes.push(name.value);
        break;
      case eDataFieldName.ten:
        codes.push(data);
        break;
    }
    setDataSource((prevDataSource: any) => {
      const newDataSource = [...prevDataSource];
      for (let i = 0; i < newDataSource.length; i++) {
        if (newDataSource[i].key === id) {
          switch (type) {
            case eDataFieldName.ma:
              if (name) {
                newDataSource[i].ten = renderAutoComplete(
                  id,
                  optionsName,
                  name.label,
                  getPlaceholder(eDataFieldName.ten),
                  eDataFieldName.ten,
                );
                newDataSource[i].ma = renderAutoComplete(
                  id,
                  optionsCode,
                  name.value,
                  getPlaceholder(eDataFieldName.ma),
                  eDataFieldName.ma,
                  { width: 150 },
                );
                newDataSource[i].donvi = <Input value={getDonViTinh(name.value)} readOnly />;
              }
              break;
            case eDataFieldName.ten:
              newDataSource[i].ten = renderAutoComplete(
                id,
                optionsName,
                name?.label || '',
                getPlaceholder(eDataFieldName.ten),
                eDataFieldName.ten,
              );
              newDataSource[i].ma = renderAutoComplete(
                id,
                optionsCode,
                data,
                getPlaceholder(eDataFieldName.ma),
                eDataFieldName.ma,
                { width: 150 },
              );
              newDataSource[i].donvi = <Input value={getDonViTinh(data)} readOnly />;
              break;
          }
        }
        const m = getData(newDataSource[i].ma);
        if (m) {
          const tk_thuchien = tonKho.find( t => t.ma_vt === m);
          const luongTon = tk_thuchien ? tk_thuchien.luong_ton : 0;
          const dx = dataModifying[newDataSource[i].key]?.dexuat || 0;
          newDataSource[i].tonkho = renderInput(true, luongTon.toString(), newDataSource[i].key, eDataFieldName.tonkho);
          newDataSource[i].klconlai = renderInput(true, tinhKLConlai(0, luongTon, dx).toString(), newDataSource[i].key, eDataFieldName.klconlai);
          const tk_kehoach = tonKhoTheoNgay.find(t => t.ma_vt === m);
          const luong_xuat = tk_kehoach?.luong_xuat || 0;
          newDataSource[i].klkehoach = <Input value={luong_xuat} readOnly />;
        }
      }
      return newDataSource;
    });
  };

  const renderAutoComplete = (
    newKey: string,
    options: AutoCompleteOptions[],
    value: string,
    placeholder: string,
    typeHandleSelect: eDataFieldName,
    dropdownStyle?: React.CSSProperties,
    status?: 'error' | 'warning',
  ) => {
    return (
      <AutoCompleteCustom
        className={styles.newRow}
        optionsList={options}
        id={newKey}
        status={status}
        onChange={(key: string, data: string) => {
          // console.log('onChange ', newKey, data);
          // console.log(dataSource);
        }}
        warning={tCommon('Value does not exist')}
        onBlur={(key: string, data: any) => {
          setDataSource((prevDataSource: ProposalType[]) => {
            const newDataSource = [...prevDataSource];
            for (let i = 0; i < newDataSource.length; i++) {
              if (newDataSource[i].key === key) {
                switch (typeHandleSelect) {
                  case eDataFieldName.ma:
                    newDataSource[i].ma = renderAutoComplete(
                      key,
                      optionsCode,
                      data,
                      getPlaceholder(eDataFieldName.ma),
                      eDataFieldName.ma,
                      { width: 150 },
                    );
                    break;
                  case eDataFieldName.ten:
                    newDataSource[i].ten = renderAutoComplete(
                      key,
                      optionsName,
                      data,
                      getPlaceholder(eDataFieldName.ten),
                      eDataFieldName.ten,
                    );
                    break;
                  default:
                }
              }
            }
            return newDataSource;
          });
        }}
        onSelect={(id: string, data: any) => {
          handelSelect(id, data, typeHandleSelect, newKey);
        }}
        value={value}
        placeholder={placeholder}
        dropdownStyle={dropdownStyle}
      />
    );
  };

  const renderInputNumber = (readOnly: boolean, value: string, key: string, type: eDataFieldName) => {
    return (
      <InputNumber
        className={styles.newRow}
        readOnly={readOnly}
        defaultValue={value}
        onChange={(v: string | null) => {
          // console.log(v, dataSource);
          updateData(v, type, key);
        }}
      />
    );
  };

  const renderInput = (readOnly: boolean, value: string, key: string, type: eDataFieldName) => {
    if (readOnly) {
      return (
        <Input
          className={styles.newRow}
          readOnly={readOnly}
          value={value}
          onChange={v => {
            updateData(v.target.value, type, key);
          }}
        />
      );
    }
    return (
      <Input
        className={styles.newRow}
        readOnly={readOnly}
        defaultValue={value}
        onChange={v => {
          updateData(v.target.value, type, key);
        }}
      />
    );
  };

  const updateData = (data: string | null, type: eDataFieldName, key: string) => {
    setDataSource((prevDataSource: ProposalType[]) => {
      const newDataSource = [...prevDataSource];
      for (let i = 0; i < newDataSource.length; i++) {
        if (newDataSource[i].key === key) {
          const prev = dataModifying[newDataSource[i].key];
          switch (type) {
            case eDataFieldName.dexuat:
              let dx = 0;
              if (data) {
                try {
                  dx = parseFloat(data);
                } catch (error) {}
              }
              dataModifying = {
                ...dataModifying,
                [newDataSource[i].key]: { ...prev, dexuat: dx },
              };
              // newDataSource[i].dexuat = renderInput(false, data || '', key, eDataFieldName.dexuat);
              const klDinhMuc = 0;
              const tonkho = getData(newDataSource[i].tonkho);
              const klconlai = tinhKLConlai(klDinhMuc, tonkho, dx);
              // console.log('klconlai ', klconlai);
              newDataSource[i].klconlai = renderInput(
                true,
                Utils.fixNumber(klconlai).toString(),
                key,
                eDataFieldName.klconlai,
              );
              break;
            case eDataFieldName.ghichu:
              dataModifying = {
                ...dataModifying,
                [newDataSource[i].key]: { ...prev, ghichu: data || '' },
              };
              // newDataSource[i].ghi_chu = renderInput(false, data || '', key, eDataFieldName.ghichu);
              break;
            case eDataFieldName.maKho:
              dataModifying = {
                ...dataModifying,
                [newDataSource[i].key]: { ...prev, maKho: data || '' },
              };
              // newDataSource[i].ghi_chu = renderInput(false, data || '', key, eDataFieldName.ghichu);
              break;
            case eDataFieldName.tenKho:
              dataModifying = {
                ...dataModifying,
                [newDataSource[i].key]: { ...prev, tenKho: data || '' },
              };
              // newDataSource[i].ghi_chu = renderInput(false, data || '', key, eDataFieldName.ghichu);
              break;
            case eDataFieldName.Mavv:
              dataModifying = {
                ...dataModifying,
                [newDataSource[i].key]: { ...prev, Mavv: data || '' },
              };
              // newDataSource[i].ghi_chu = renderInput(false, data || '', key, eDataFieldName.ghichu);
              break;
            case eDataFieldName.DienGiai:
              dataModifying = {
                ...dataModifying,
                [newDataSource[i].key]: { ...prev, DienGiai: data || '' },
              };
              // newDataSource[i].ghi_chu = renderInput(false, data || '', key, eDataFieldName.ghichu);
              break;
            case eDataFieldName.gia1:
              dataModifying = {
                ...dataModifying,
                [newDataSource[i].key]: { ...prev, gia1: data || '' },
              };
              break;
            case eDataFieldName.gia2:
              dataModifying = {
                ...dataModifying,
                [newDataSource[i].key]: { ...prev, gia2: data || '' },
              };
              break;
            case eDataFieldName.gia3:
              dataModifying = {
                ...dataModifying,
                [newDataSource[i].key]: { ...prev, gia3: data || '' },
              };
              break;
            case eDataFieldName.ncc1:
              dataModifying = {
                ...dataModifying,
                [newDataSource[i].key]: { ...prev, ncc1: data || '' },
              };
              break;
            case eDataFieldName.ncc2:
              dataModifying = {
                ...dataModifying,
                [newDataSource[i].key]: { ...prev, ncc2: data || '' },
              };
              break;
            case eDataFieldName.ncc3:
              dataModifying = {
                ...dataModifying,
                [newDataSource[i].key]: { ...prev, ncc3: data || '' },
              };
              break;
            default:
          }
        }
      }
      return newDataSource;
    });
  };

  const removeItem = (key: string) => {
    setDataSource((prevDataSource: any[]) => {
      delete dataModifying[key];
      return prevDataSource.filter(i => i.key !== key);
    });
  };

  const getPlaceholder = (typeValue: eDataFieldName) => {
    switch (typeValue) {
      case eDataFieldName.ten:
        return currentScreen !== eTypeVatTuMayMoc.MayMoc ? t('Enter material name') : t('Enter machines name');
      case eDataFieldName.ma:
        return currentScreen !== eTypeVatTuMayMoc.MayMoc ? t('Enter material code') : t('Enter machines code');
    }
    return '';
  };

  const getKhoHienTai = () => {
    let maKho = '';
    let tenKho = '';
    const danhSachMakho: string[] = GetDanhSachMaKho();
    if (danhSachMakho && danhSachMakho.length) {
      switch (currentScreen) {
        case eTypeVatTuMayMoc.VatTuChinh:
        case eTypeVatTuMayMoc.VatTuPhu:
          maKho = danhSachMakho.find(i => !i.includes('CCDC')) || '';
          break;
        case eTypeVatTuMayMoc.MayMoc:
          maKho = danhSachMakho.find(i => i.includes('CCDC')) || '';
          break;
        default:
      }
      if (danhSachMakho.length === 1 && maKho === '') {
        maKho = danhSachMakho[0];
      }
    }
    tenKho = getTenKho(maKho);
    return { maKho, tenKho };
  };

  const getTenKho = (maKho: string) => {
    let tenKho = '';
    if (maKho && wareHouses && wareHouses.length) {
      const k = wareHouses.find(w => w.ma_kho === maKho);
      if (k) tenKho = k.ten_kho;
    }
    return tenKho;
  };

  const handleAddRow = () => {
    const newKey = Utils.generateRandomString(15);
    // console.log('handleAddRow ', newKey);
    const kho = getKhoHienTai();
    let maKho = kho.maKho || '';
    let tenKho = kho.tenKho || '';
    console.log('maKho ', maKho);
    console.log('tenKho ', tenKho);
    dataModifying = {
      ...dataModifying,
      [newKey]: { maKho, tenKho, dexuat: 0 },
    };
    const newRow: ProposalType = {
      key: newKey,
      ma: renderAutoComplete(newKey, optionsCode, '', getPlaceholder(eDataFieldName.ma), eDataFieldName.ma, {
        width: 150,
      }),
      ten: renderAutoComplete(newKey, optionsName, '', getPlaceholder(eDataFieldName.ten), eDataFieldName.ten),
      donvi: renderInputNumber(true, '', newKey, eDataFieldName.donvi),
      klkehoach: renderInputNumber(true, '0', newKey, eDataFieldName.klkehoach),
      tonkho: renderInputNumber(true, '0', newKey, eDataFieldName.tonkho),
      klconlai: renderInputNumber(true, '0', newKey, eDataFieldName.klconlai),
      dexuat: renderInputNumber(false, '0', newKey, eDataFieldName.dexuat),

      gia1: renderInputNumber(false, '0', newKey, eDataFieldName.gia1),
      gia2: renderInputNumber(false, '0', newKey, eDataFieldName.gia2),
      gia3: renderInputNumber(false, '0', newKey, eDataFieldName.gia3),
      ncc1: renderInput(false, '', newKey, eDataFieldName.ncc1),
      ncc2: renderInput(false, '', newKey, eDataFieldName.ncc2),
      ncc3: renderInput(false, '', newKey, eDataFieldName.ncc3),
      // ngaynhap: <DatePicker className={styles.newRow} />,
      ghichu: renderInput(false, '', newKey, eDataFieldName.ghichu),
      maKho: renderInput(true, maKho, newKey, eDataFieldName.maKho),
      tenKho: renderInput(true, tenKho, newKey, eDataFieldName.tenKho),
      Mavv: renderInput(false, '', newKey, eDataFieldName.Mavv),
      DienGiai: renderInput(false, '', newKey, eDataFieldName.DienGiai),
    };
    setDataSource((prevDataSource: any) => {
      const newDataSource = [...prevDataSource, newRow];
      return newDataSource;
    });
    setNewRows(new Set(newRows.add(newKey)));
  };

  const getTenVatTuVaDonViTinh = (ma: string) => {
    let ten = '';
    let donViTinh = '';
    switch (currentScreen) {
      case eTypeVatTuMayMoc.VatTuChinh:
      case eTypeVatTuMayMoc.VatTuPhu:
        if (producsts && producsts.length) {
          const vt = producsts.find(p => p.ma_vt === ma);
          if (vt) {
            ten = vt.ten_vt;
            donViTinh = vt.dvt;
          }
        }
        break;
      case eTypeVatTuMayMoc.MayMoc:
        if (machineries && machineries.length) {
          const vt = machineries.find(p => p.ma_Tscd === ma);
          if (vt) {
            ten = vt.ten_Tscd;
            donViTinh = vt.ccdc_Dvt;
          }
        }
        break;
    }
    return { ten, donViTinh };
  };

  useEffect(() => {
    // createDanhSachVatTu(ChiTietHangHoa);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ChiTietHangHoa]);

  const createDanhSachVatTu = (dsChiTietDeNghiMuaHang: ChiTietDeNghiMuaHangDTO[]) => {
    const datas: ProposalType[] = [];
    dataModifying = {};
    const mavattu: string[] = [];
    if (dsChiTietDeNghiMuaHang) {
      dsChiTietDeNghiMuaHang.forEach(c => {
        const newKey = c.guid || Utils.generateRandomString(15);
        mavattu.push(c.ma_vt);
        const result = getTenVatTuVaDonViTinh(c.ma_vt);
        let ten = result.ten;
        let dvt = result.donViTinh;
        let klkehoach = '';
        let tonkho = c.ton_kho || 0;
        let klconlai = tinhKLConlai(0, tonkho, c.so_luong_yeu_cau / 1);
        let note = '';
        let tenKho = getTenKho(c.ma_kho);
        dataModifying = {
          ...dataModifying,
          [newKey]: {
            dexuat: c.so_luong_yeu_cau / 1,
            // ghichu: ,
            maKho: c.ma_kho,
            tenKho: tenKho,
            // Mavv: ,
            DienGiai: c.dien_giai,
            guid: c.guid,
            gia1: c.gia1?.toString() || '0',
            gia2: c.gia2?.toString() || '0',
            gia3: c.gia3?.toString() || '0',
            ncc1: c.nhaCungCap1 || '',
            ncc2: c.nhaCungCap2 || '',
            ncc3: c.nhaCungCap3 || '',
          },
        };
        const newRow: ProposalType = {
          key: newKey,
          ma: renderAutoComplete(newKey, optionsCode, c.ma_vt, getPlaceholder(eDataFieldName.ma), eDataFieldName.ma, {
            width: 150,
          }),
          ten: renderAutoComplete(newKey, optionsName, ten, getPlaceholder(eDataFieldName.ten), eDataFieldName.ten),
          donvi: renderInputNumber(true, dvt, newKey, eDataFieldName.donvi),
          klkehoach: renderInputNumber(true, klkehoach || '0', newKey, eDataFieldName.klkehoach),
          tonkho: renderInputNumber(true, tonkho ? tonkho.toString() : '0', newKey, eDataFieldName.tonkho),
          klconlai: renderInputNumber(true, klconlai ? klconlai.toString() : '0', newKey, eDataFieldName.klconlai),
          dexuat: renderInputNumber(false, c.so_luong_yeu_cau.toString(), newKey, eDataFieldName.dexuat),
          gia1: renderInputNumber(false, c.gia1?.toString() || '', newKey, eDataFieldName.gia1),
          ncc1: renderInput(false, c.nhaCungCap1?.toString() || '', newKey, eDataFieldName.ncc1),
          gia2: renderInputNumber(false, c.gia2?.toString() || '', newKey, eDataFieldName.gia2),
          ncc2: renderInput(false, c.nhaCungCap2?.toString() || '', newKey, eDataFieldName.ncc2),
          gia3: renderInputNumber(false, c.gia3?.toString() || '', newKey, eDataFieldName.gia3),
          ncc3: renderInput(false, c.nhaCungCap3?.toString() || '', newKey, eDataFieldName.ncc3),
          // ngaynhap: <DatePicker className={styles.newRow} />,
          ghichu: renderInput(false, note, newKey, eDataFieldName.ghichu),
          maKho: renderInput(true, c.ma_kho, newKey, eDataFieldName.maKho),
          tenKho: renderInput(true, tenKho, newKey, eDataFieldName.tenKho),
          Mavv: renderInput(false, '', newKey, eDataFieldName.Mavv),
          DienGiai: renderInput(false, c.dien_giai, newKey, eDataFieldName.DienGiai),
        };
        datas.push(newRow);
      });
    }
    setDataSource(datas);
    GetTonKho(mavattu);
  };

  //#region convertPhieuDeXuat
  const convertPhieuDeXuat = (data: PhieuDeNghiMuaHangDTO) => {
    const { chiTietHangHoa, chiTietDeNghiMuaHang, recId } = data;
    // recId && dispatch(accountingInvoiceActions.GetDanhSachPhieuDeNghiMuaHang_ChiTietHangHoa({ params: { recId: recId } }));;
    const { createDate, dien_giai, capDuyet, ngay_hoa_don } = data;
    setDaySelected(dayjs(createDate));
    ngay_hoa_don && setGoodsReceiptDate(dayjs(ngay_hoa_don));
    setDienGiai(dien_giai);
    capDuyet && setcapDuyet(capDuyet / 1);
    chiTietDeNghiMuaHang && createDanhSachVatTu(chiTietDeNghiMuaHang);
    console.log(data);
    const bophan = danhSachBoPhan?.find((b)=> b.ma_bo_phan === data.ma_bo_phan);
    bophan && setDepartment(bophan.ten_bo_phan);
    // setMaPhieu('');
    // setCategory(1);
  };
  const columns: ColumnType<ProposalType>[] = [
    {
      title: <span className={styles.tableHeader}>Mã Kho</span>,
      dataIndex: 'maKho',
      key: 'maKho',
      width: 120,
      className: styles.tablecell,
      render: (text: string | JSX.Element) => (
        <span className={typeof text === 'string' && ['Kho1', 'Kho2'].includes(text) ? styles.underlineText : ''}>
          {text}
        </span>
      ),
      align: 'center',
      // fixed: 'left',
    },
    {
      title: <span className={styles.tableHeader}>Tên Kho</span>,
      dataIndex: 'tenKho',
      key: 'tenKho',
      width: 200,
      className: styles.tablecell,
      render: (text: string | JSX.Element) => (
        <span className={typeof text === 'string' && ['Kho1', 'Kho2'].includes(text) ? styles.underlineText : ''}>
          {text}
        </span>
      ),
      align: 'center',
      // fixed: 'left',
    },
    {
      title: <span className={styles.tableHeader}>{currentScreen === eTypeVatTuMayMoc.MayMoc ? t('Machinery code') : t('Material code')}</span>,
      dataIndex: 'ma',
      key: 'ma',
      width: 180,
      className: styles.tablecell,
      render: (text: string | JSX.Element) => (
        <span className={typeof text === 'string' && ['BT1', 'Thep1'].includes(text) ? styles.underlineText : ''}>
          {text}
        </span>
      ),
      align: 'center',
    },
    {
      title: (
        <span className={styles.tableHeader}>
          {currentScreen === eTypeVatTuMayMoc.MayMoc ? t('Machinery name') : t('Material name')}
        </span>
      ),
      dataIndex: 'ten',
      key: 'ten',
      width: 400,
      className: styles.tablecell,
      render: (text: string | JSX.Element) => (
        <span className={typeof text === 'string' && text === 'Tổng' ? styles.boldText : ''}>{text}</span>
      ),
      align: 'center',
    },
    {
      title: '',
      key: 'operation',
      // fixed: 'right',
      width: 40,
      align: 'center',
      render: (_: any, record: any) => {
        const showDelete = record.key !== 'summary' && !record.key.startsWith('add-');
        return (
          showDelete && (
            <DeleteOutlined
              onClick={e => {
                const ma = getData(record.ma);
                if (ma) {
                  setNotification(tCommon('Do you want to delete?'));
                  setIsModalOpen(true);
                  setRemoveId(record.key);
                } else {
                  removeItem(record.key);
                }
              }}
              style={{ fontSize: '18px', color: '#FF0000', border: 'node' }}
            />
          )
        );
      },
    },
    {
      title: <span className={styles.tableHeader}>{t('Unit')}</span>,
      dataIndex: 'donvi',
      key: 'donvi',
      width: 100,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    },
    // {
    //   title: <span className={styles.tableHeader}>Mã vụ việc</span>,
    //   dataIndex: 'Mavv',
    //   key: 'Mavv',
    //   width: 100,
    //   render: (text: string | JSX.Element) => text,
    //   className: styles.tablecell,
    //   align: 'center',
    // },
    {
      title: <span className={styles.tableHeader}>Diễn giải</span>,
      dataIndex: 'DienGiai',
      key: 'DienGiai',
      width: 200,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Planned volume')}</span>,
      dataIndex: 'klkehoach',
      key: 'klkehoach',
      width: 100,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Actual inventory')}</span>,
      dataIndex: 'tonkho',
      key: 'tonkho',
      width: 120,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Remaining mass')}</span>,
      dataIndex: 'klconlai',
      key: 'klconlai',
      width: 80,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('This time proposal')}</span>,
      dataIndex: 'dexuat',
      key: 'dexuat',
      width: 130,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Price')} 1</span>,
      dataIndex: 'gia1',
      key: 'gia1',
      width: 130,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Supplier')} 1</span>,
      dataIndex: 'ncc1',
      key: 'ncc1',
      width: 300,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    },{
      title: <span className={styles.tableHeader}>{t('Price')} 2</span>,
      dataIndex: 'gia2',
      key: 'gia2',
      width: 130,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Supplier')} 2</span>,
      dataIndex: 'ncc2',
      key: 'ncc2',
      width: 300,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    },{
      title: <span className={styles.tableHeader}>{t('Price')} 3</span>,
      dataIndex: 'gia3',
      key: 'gia3',
      width: 130,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    },
    {
      title: <span className={styles.tableHeader}>{t('Supplier')} 3</span>,
      dataIndex: 'ncc3',
      key: 'ncc3',
      width: 300,
      render: (text: string | JSX.Element) => text,
      className: styles.tablecell,
      align: 'center',
    },
    // {
    //   title: <span className={styles.tableHeader}>{t('Date of entry required')}</span>,
    //   dataIndex: 'ngaynhap',
    //   key: 'ngaynhap',
    //   width: 160,
    //   render: (text: string | JSX.Element) => text,
    //   className: styles.tablecell,
    //   align: 'center',
    // },
    // {
    //   title: <span className={styles.tableHeader}>{t('Note')}</span>,
    //   dataIndex: 'ghichu',
    //   key: 'ghichu',
    //   width: 250,
    //   render: (text: string | JSX.Element) => text,
    //   className: styles.tablecell,
    //   align: 'center',
    // },
  ];

  const addButtonRow: ProposalType = {
    key: `add-${(dataSource.length + 1).toString()}`,
    ma: (
      <Button className={styles.addButton} onClick={handleAddRow}>
        +
      </Button>
    ),
    ten: '',
    donvi: '',
    klkehoach: '',
    tonkho: '',
    klconlai: '',
    dexuat: '',
    ngaynhap: '',
    ghichu: '',
    isRowFuction: true,
    maKho: '',
    tenKho: '',
    Mavv: '',
    DienGiai: '',
  };

  let ItemTong = null;
  let ItemAdd = null;
  const PData: ProposalType[] = [];
  dataSource.forEach((d, i) => {
    if (d.key === 'summary') {
      ItemTong = d;
    } else if (d.key.startsWith('add-')) {
      ItemAdd = d;
    } else {
      PData.push(d);
    }
  });
  const updatedDataSource = [
    ...PData,
    // ItemTong ? ItemTong : summary,
    // ItemAdd ? ItemAdd : addButtonRow
  ];

  const formatDateAPI = (date: any) => {
    return date ? dayjs(date).format(FormatDateAPI) : dayjs().format(FormatDateAPI);
  };

  const create_nguoi_tt = () => {
    let nguoi_tt = '';
    if (userData.id) {
      nguoi_tt += `ID_[${userData.id}]`
    }
    if (userData.userName) {
      nguoi_tt += nguoi_tt === '' ? `USERNAME_[${userData.userName}]` : `_USERNAME_[${userData.userName}]`;
    }
    if (userData.email) { 
      nguoi_tt += nguoi_tt === '' ? `EMAIL_[${userData.email}]` : `_EMAIL_[${userData.email}]`;
    }
    if (userData.phone) { 
      nguoi_tt += nguoi_tt === '' ? `PHONE_[${userData.phone}]` : `_PHONE_${userData.phone}]`;
    }
    return nguoi_tt;
  };

  const checkCode = () => {
    let error = false;
    const newDataSource = [...dataSource];
    for (let i = 0; i < newDataSource.length; i++) {
      const temp = newDataSource[i];
      const ma = getData(temp.ma);
      console.log('ma ', ma);
      const index = optionsCode.findIndex(c => c.label === ma);
      if (index < 0 && ma !== '') {
        newDataSource[i].ma = renderAutoComplete(
          temp.key,
          optionsCode,
          ma,
          getPlaceholder(eDataFieldName.ma),
          eDataFieldName.ma,
          {},
          'error',
        );
        error = true;
      }
    }
    console.log(newDataSource);
    setDataSource(newDataSource);
    return error;
  };

  //#region handleSave
  function handleSave() {
    const isError = checkCode();
    if (isError) return;
    const PhieuDeXuat = GenerateOutgoingData();
    console.log('PhieuDeXuat ', PhieuDeXuat.vat_tu_may_moc);
    if (PhieuDeXuat.vat_tu_may_moc.length === 0) {
      setNotification(tCommon('The proposal list is not blank'));
      setIsModalOpen(true);
      return;
    }
    const inputData: any = proposalFormSelected || {};
    let dsChiTietDeNghiMuaHang = proposalFormSelected?.chiTietDeNghiMuaHang;
    let nguoi_tt = create_nguoi_tt();
    const PhieuDeNghiMuaHang: PhieuDeNghiMuaHangDTO = {
      ...inputData,
      ma_bo_phan: department,
      madvcs: madvcs.THUCHIEN,
      dien_giai: PhieuDeXuat.dien_giai,
      ngay_hoa_don: PhieuDeXuat.ngay_hoa_don,
      // createDate: formatDateAPI(PhieuDeXuat.ngay_tao),
      // CreateDate: formatDateAPI(PhieuDeXuat.ngay_tao),
      capDuyet: PhieuDeXuat.capDuyet,
      dia_chi: inputData?.dia_chi || '',
      ngay_ct: formatDateAPI(PhieuDeXuat.ngay_tao),
      ma_nt: inputData?.ma_nt || 'VND',
      // ma_ct: PhieuDeXuat.ma_phieu,
      nv_bh: proponent, // Utils.stringify(user),
      hoaDonVAT: inputData?.hoaDonVAT || [],
      nguoi_tt,
      chiTietHangHoa: inputData?.chiTietHangHoa || [],
      list_of_extensions: inputData?.list_of_extensions || [],
      chiTietDeNghiMuaHang: PhieuDeXuat.vat_tu_may_moc.map(d => {
        let ct: any = {};
        if (dsChiTietDeNghiMuaHang) {
          ct = dsChiTietDeNghiMuaHang.find(c => c.guid === d.guid) || {};
        }
        const temp: ChiTietDeNghiMuaHangDTO = {
          ...ct,
          // createDate: ct.createDate ? formatDateAPI(ct.createDate) : formatDateAPI(PhieuDeXuat.ngay_tao),
          // CreateDate: ct.createDate ? formatDateAPI(ct.createDate) : formatDateAPI(PhieuDeXuat.ngay_tao),
          ma_kho: d.maKho, // 'PHANTON',//
          ma_vt: d.ma,
          // ??: Mã vụ việc
          dien_giai: d.DienGiai,
          // ?? : KL kế hoạch
          ton_kho: d.ton_kho_thuc_te,
          // ??: KL còn lại
          so_luong_yeu_cau: d.de_xuat_lan_nay,
          // ??: Ghi chú
          so_luong_thuc_te: ct.so_luong_thuc_te || 0,
          // ma_kh: "",
          // gia: ct.gia || 0,
          // "ma_kho": "TANTHAI",
          // gia_gan_nhat: 0,
          // nhaCungCap1: "mã nhà cung cấp đề xuất ",
          // nhaCungCap2: "mã nhà cung cấp đề xuất "
          gia1: d.gia1,
          gia2: d.gia2,
          gia3: d.gia3,
          nhaCungCap1: d.nhaCungCap1,
          nhaCungCap2: d.nhaCungCap2,
          nhaCungCap3: d.nhaCungCap3,
        };
        return temp;
      }),
    };
    const arrayDelete: string[] = [];
    if (dsChiTietDeNghiMuaHang) {
      dsChiTietDeNghiMuaHang.forEach(c => {
        const index = PhieuDeNghiMuaHang.chiTietDeNghiMuaHang?.findIndex(c1 => c1.guid === c.guid);
        if (index === -1) {
          c.guid && arrayDelete.push(c.guid);
          // PhieuDeNghiMuaHang.chiTietDeNghiMuaHang?.push({...c, del: true});
        }
      });
      console.log('arrayDelete ', arrayDelete);
    }
    if (proposalFormSelected) {
      PhieuDeNghiMuaHang.guid = proposalFormSelected.guid;
    }
    console.log('user ', user);
    console.log('PhieuDeNghiMuaHang ', PhieuDeNghiMuaHang);
    if(arrayDelete.length) {
      dispatch(
        accountingInvoiceActions.DeletePhieuDeNghiMuaHang({
          ids: arrayDelete,
          params: undefined,
        }),
      );
    }
    dispatch(accountingInvoiceActions.CreateProposalForm({ data: PhieuDeNghiMuaHang, params: {} }));
    // resetDataSource();
    // handleClose();
  }

  const getTitle = () => {
    let title = '';
    if (proposalFormSelected) {
      title += `${t('EDIT')} `;
    } else {
      title += `${t('CREATE NEW')} `;
    }
    switch (currentScreen) {
      case eTypeVatTuMayMoc.VatTuChinh:
        title += `${t('MATERIAL PROPOSAL FORM')} ${t('MAIN')}`;
        break
      case eTypeVatTuMayMoc.VatTuPhu:
        title += `${t('MATERIAL PROPOSAL FORM')} ${t('EXTRA')}`;
        break
      case eTypeVatTuMayMoc.MayMoc:
        title += t('MACHINERY PROPOSAL FORM');
        break
    }
    return title;
  };
  const disabledDate = (current: Dayjs) => {
    // So sánh ngày hiện tại với ngày hôm nay
    return current && current < daySelected.startOf('day');
  };

  useEffect(() => {
    console.log('department ', department);
  }, [department]);

  const SplitBySupplier = () => {};
  const GetTheLowestSupplierPrice = () => {};
  const GetTheNearestSupplierPrice = () => {};
  //#region Element
  return (
    <div style={{ flex: 1, flexDirection: 'column' }}>
      <h1 className={styles.formtitle}>{getTitle()}</h1>
      <div style={{ height: 500 }}>
        <Space style={{ marginBottom: 5, width: '100%' }}>
          {/* <Space style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <span className={styles.labeltext}>{t('Form code')}</span>
            <div>
              <Input type="text" className={styles.inputfield} value={maPhieu}/>
            </div>
          </Space> */}
          {/* <Space style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <span className={styles.labeltext}>
              {t('Belongs to category / Father job')}
              <span className="fadedText">{t('(Optional)')}</span>
            </span>
            <div>
              <Select
                defaultValue={categorys?.find(c => c.id === category)?.name}
                className={styles.selectfield}
                options={Category(categorys, tCategory).map(item => ({ value: item.id, label: item.label }))}
                value={category}
                onSelect={setCategory}
              />
            </div>
          </Space>  */}
          <Space style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <span className={styles.labeltext}>{t('Cấp duyệt')}</span>
            <div>
              <Select
                defaultValue={capDuyet}
                style={{ width: 80 }}
                options={level.map(item => ({ value: item, label: item }))}
                value={capDuyet}
                onSelect={l => {
                  setcapDuyet(l);
                }}
              />
            </div>
          </Space>
          <Space style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <span className={styles.labeltext}>{t('Date of creation of ticket')}</span>
            <div>
              <DatePicker
                allowClear={false}
                className={styles.datepicker}
                value={daySelected}
                onChange={(date, dateString) => {
                  date && setDaySelected(date);
                }}
                disabled
              />
            </div>
          </Space>
          <Space style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <span className={styles.labeltext}>{t('Goods receipt date')}</span>
            <div>
              <DatePicker
                allowClear={false}
                className={styles.datepicker}
                value={GoodsReceiptDate}
                onChange={(date, dateString) => {
                  date && setGoodsReceiptDate(date);
                }}
                disabledDate={disabledDate}
              />
            </div>
          </Space>
          <Space style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <span className={styles.labeltext}>{t('Proposal Department')}</span>
            <div>
              <Select
                defaultValue={department}
                style={{ width: 200 }}
                options={danhSachBoPhan?.map(item => ({ value: item.ma_bo_phan, label: item.ten_bo_phan }))}
                value={department}
                onSelect={(l, o) => {
                  o && setDepartment(o.value);
                }}
              />
            </div>
          </Space>
          {/* <Space style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <span className={styles.labeltext}>{t('Belong to the department')}</span>
            <Select
                defaultValue={categorys?.find(c => c.id === category)?.name}
                className={styles.selectfield}
                options={[]}
                value={department}
                // onSelect={setDepartment}
              />
          </Space> */}
        </Space>
        <div style={{ marginBottom: 5, width: '100%', display: 'flex' }}>
          <div
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginRight: 10, marginBottom: 5 }}
          >
            <span className={styles.labeltext}>{t('Proponent')}</span>
            {/* <Input style={{ width: '100%' }} value={Utils.getFullName(user)} /> */}
            <AutoCompleteCustom
              id={Utils.generateRandomString(3)}
              value={proponent}
              optionsList={employeesByCompanyId?.map(e => {
                const name = Utils.getFullName(e);
                const t: AutoCompleteOptions = {
                  label: name,
                  value: e.id.toString(),
                };
                return t;
              })}
              warning=''
              onBlur={(id, data) => {
                setProponent(data);
              }}
              onChange={() => {}}
              onSelect={(id, data, label) => {
                const em = employeesByCompanyId.find(e => e.id.toString() === data);
                setProponent(label);
                console.log(em);
                setUserData({
                  email: em?.contactDetail?.workEmail || '',
                  phone: em?.contactDetail?.workPhone || '',
                  id: data,
                  userName: '',
                });
              }}
              className={''}
              placeholder={''}
              style={{ width: 200 }}
            />
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '100%', marginBottom: 5 }}
          >
            <span className={styles.labeltext} style={{ flex: 1 }}>
              {t('Interpret')}
            </span>
            <Input
              style={{ width: '100%' }}
              value={dienGiai}
              onChange={value => {
                setDienGiai(value.target.value);
              }}
            />
          </div>
        </div>
        <Table
          rowKey={record => {
            let key = record?.key;
            if (key === undefined || key === null) {
              key = Utils.generateRandomString(5);
            }
            return key;
          }}
          dataSource={updatedDataSource}
          columns={columns}
          pagination={false}
          bordered
          size="middle"
          scroll={{ x: 900, y: 270 }}
          // components={<div></div>}
          loading={isLoading}
          rowClassName={record => {
            if (record) {
              return newRows.has(record.key) ? `${styles.newRow} ${styles.tableRow}` : styles.tableRow;
            }
            return '';
          }}
        />
        <Space style={{ display: 'block', paddingTop: 5, paddingBottom: 5 }}>
          <Button onClick={handleAddRow} style={{ width: 80 }} type={'default'} disabled={isLoading}>
            +
          </Button>
        </Space>
      </div>
      <Space
        style={{
          display: 'flex',
          // paddingTop: 5,
          // paddingBottom: 5,
          // position: 'absolute',
          // bottom: 10,
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div>
          <Button
            type="primary"
            // className={styles.button}
            // style={{ marginRight: 80 }}
            disabled
            onClick={() => {
              SplitBySupplier();
            }}
          >
            {t('Split by supplier')}
          </Button>
        </div>
        {/* <Button type="primary">{t('Import to warehouse')}</Button> */}
        <div>
          <Button
            type="primary"
            // className={styles.button}
            style={{ marginRight: 10 }}
            disabled
            onClick={() => {
              GetTheLowestSupplierPrice();
            }}
          >
            {t('Get the lowest supplier price')}
          </Button>
          <Button
            type="primary"
            // className={styles.button}
            disabled
            style={{ marginRight: 10 }}
            onClick={() => {
              GetTheNearestSupplierPrice();
            }}
          >
            {t('Get the nearest supplier price')}
          </Button>
        </div>
        <div>
          <Button
            type="primary"
            // className={styles.button}
            // style={{ marginRight: 80 }}
            onClick={() => {
              handleSave();
            }}
            disabled={isLoading}
          >
            {t('Submit a proposal')}
          </Button>
        </div>
      </Space>
      <Confirm
        modalVisible={isModalOpen}
        setModalVisible={setIsModalOpen}
        notification={notification}
        buttons={{
          ok: {
            label: '',
            action: function (): void {
              console.log('ok');
              removeId && removeItem(removeId);
              setRemoveId(undefined);
              setIsModalOpen(false);
            },
          },
          cancel: {
            label: '',
            action: function (): void {
              console.log('cancel');
              setRemoveId(undefined);
              setIsModalOpen(false);
            },
          },
        }}
      />
    </div>
  );
};

export default NewMachineryMaterialList;

import { catchError, concat, concatMap, delay, filter, from, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';

import { projectActions } from './projectSlice';
import { issueActions } from '../issue';
import { startLoading, stopLoading } from '../loading';
import { hideModal } from '../modal';
import { RootEpic } from '../types';
import {
  AddMemberToProjectModalName,
  CreateManyProjectMemberLoadingKey,
  EditProjectMemberModalName,
  GettingProjectMembers,
  GettingProjectRolesLoadingKey,
  GettingProjectStatusList,
  SavingProject,
  SavingProjectMemberLoadingKey,
} from '@/common/define';
import { CreateFolderRootProject } from '@/common/project';
import { ProjectService } from '@/services/ProjectService';
import Utils from '@/utils';

const getProjects$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.getProjectsRequest.match),
    switchMap(_action => {
      return concat(
        [startLoading({ key: 'getProjects' })],
        ProjectService.Get.getProjects().pipe(
          switchMap(result => {
            return [projectActions.setProjectsResponse(result)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [projectActions.setProjectsResponse([])];
          }),
        ),
        [stopLoading({ key: 'getProjects' })],
      );
    }),
  );
};

const getProjectById$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.getProjectByIdRequest.match),
    switchMap(action => {
      const id = action.payload;
      return concat(
        [startLoading({ key: 'getProjectById' })],
        ProjectService.Get.getProjectById(id).pipe(
          switchMap(result => {
            return [projectActions.setProjectByIdResponse(result)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [projectActions.setProjectByIdResponse(null)];
          }),
        ),
        [stopLoading({ key: 'getProjectById' })],
      );
    }),
  );
};

const getProjectsByCompanyId$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.getProjectsByCompanyIdRequest.match),
    switchMap(action => {
      const id = action.payload;
      return concat(
        [startLoading({ key: 'getProjectsByCompanyId' })],
        ProjectService.Get.getProjectsByCompanyId(id).pipe(
          switchMap(result => {
            return [
              projectActions.setProjectList(result),
              // projectActions.setProjectsByCompanyIdResponse(result)
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [
              projectActions.setProjectList([]),
              // projectActions.setProjectsByCompanyIdResponse([])
            ];
          }),
        ),
        [stopLoading({ key: 'getProjectsByCompanyId' })],
      );
    }),
  );
};

const getEmployeesByCompanyId$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.getEmployeesByCompanyIdRequest.match),
    switchMap(action => {
      const companyId = action.payload;
      return concat(
        [startLoading({ key: 'getEmployeesByCompanyId' })],
        ProjectService.Get.getEmployeesByCompanyId(companyId).pipe(
          switchMap(response => {
            return [projectActions.setEmployeesByCompanyIdResponse(response.results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [projectActions.setEmployeesByCompanyIdResponse([])];
          }),
        ),
        [stopLoading({ key: 'getEmployeesByCompanyId' })],
      );
    }),
  );
};

const getRolesByCompanyId$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.getRolesByCompanyIdRequest.match),
    switchMap(action => {
      const companyId = action.payload;
      return concat(
        [startLoading({ key: 'getRolesByCompanyId' })],
        ProjectService.Get.getRolesByCompanyId(companyId).pipe(
          switchMap(response => {
            return [projectActions.setRolesByCompanyIdResponse(response.results)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [projectActions.setRolesByCompanyIdResponse([])];
          }),
        ),
        [stopLoading({ key: 'getRolesByCompanyId' })],
      );
    }),
  );
};

const createProject$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.createProjectRequest.match),
    switchMap(action => {
      const data = action.payload;
      return concat(
        [startLoading({ key: 'createProject' })],
        ProjectService.Post.createProject(data).pipe(
          switchMap(result => {
            const projectName = data.name.replace(/\s+/g, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            const warehouses = [
              {
                ma_Kho: `${projectName}_CCDC`,
                ten_Kho: `${projectName}_CCDC`,
                dia_Chi: data.address,
                dien_Thoai: '',
                fax: '',
                ma_Nv: '',
                dien_Giai: '',
                in_Lookup: true,
                createDate: new Date().toISOString(),
              },
              {
                ma_Kho: `${projectName}`,
                ten_Kho: `${projectName}`,
                dia_Chi: data.address,
                dien_Thoai: '',
                fax: '',
                ma_Nv: '',
                dien_Giai: '',
                in_Lookup: true,
                createDate: new Date().toISOString(),
              }
            ];
            return [
              projectActions.setCreateProjectData(data),
              projectActions.setCreateProjectCurrentStep(3),
              projectActions.setCreateProjectResponse(result),
              projectActions.createFolderRootProject({projectId: result.id}),
              projectActions.createWarehousesRequest({projectId: result.id, data: warehouses}),
            ];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [
              projectActions.setCreateProjectData(data),
              projectActions.setCreateProjectCurrentStep(0),
              projectActions.setCreateProjectResponse(null),
            ];
          }),
        ),
        [stopLoading({ key: 'createProject' })],
      );
    }),
  );
};

const createFolderRootProject$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(projectActions.createFolderRootProject.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId } = action.payload;
      const bodyData: CreateFolderRootProject = {
        name: 'root',
        color: '',
        children: [
          {
            idChildren: '',
            type: '',
          },
        ],
        type: 'folder',
      };
      return concat(
        [startLoading({ key: 'createFolderRootProject' })],
        ProjectService.Post.CreateFolderRootProject(projectId, bodyData, {}).pipe(
          switchMap((result: any) => {
            if (result.id) {
              return from([
               projectActions.CreateLabel({projectId: projectId, bodyData: {...bodyData, name: "ungluong1", type: 'ungluong', parentId: result.id }}),
               projectActions.CreateLabel({projectId: projectId, bodyData: {...bodyData, name: "ungluong2", type: 'ungluong', parentId: result.id }}),
               projectActions.CreateLabel({projectId: projectId, bodyData: {...bodyData, name: "thanhtoan1", type: 'ungluong', parentId: result.id }}),
               projectActions.CreateLabel({projectId: projectId, bodyData: {...bodyData, name: "thanhtoan2", type: 'ungluong', parentId: result.id }}),
               projectActions.CreateLabel({projectId: projectId, bodyData: {...bodyData, name: "duthau", type: 'duthau', parentId: result.id }}),
              ]).pipe(
                concatMap(action => of(action).pipe(delay(600))) // Gọi từng API một cách tuần tự
              );
            } 
            return [projectActions.getFolderRootId({projectId: projectId})]
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'createFolderRootProject' })],
      );
    }),
  );
}

const getFolderRootId$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.getFolderRootId.match),
    switchMap(action => {
      const { projectId, isGetId } = action.payload;
      const bodyData: CreateFolderRootProject = {
        name: '',
        color: '',
        children: [
          {
            idChildren: '',
            type: '',
          },
        ],
        type: '',
        parentId: "",
      };
      return concat(
        [startLoading({ key: 'getFolderRootIds' })],
        ProjectService.Get.getFolderRootId(projectId).pipe(
          switchMap(response => {
            if (isGetId) {
              return [projectActions.getFileRoots({rootFolderId: response})]
            }
            return from([
               projectActions.CreateLabel({projectId: projectId, bodyData: {...bodyData, name: "ungluong1", type: 'ungluong', parentId: response }}),
               projectActions.CreateLabel({projectId: projectId, bodyData: {...bodyData, name: "ungluong2", type: 'ungluong', parentId: response }}),
               projectActions.CreateLabel({projectId: projectId, bodyData: {...bodyData, name: "thanhtoan1", type: 'ungluong', parentId: response }}),
               projectActions.CreateLabel({projectId: projectId, bodyData: {...bodyData, name: "thanhtoan2", type: 'ungluong', parentId: response }}),
               projectActions.CreateLabel({projectId: projectId, bodyData: {...bodyData, name: "duthau", type: 'duthau', parentId: response }}),
               projectActions.getFileRoots({rootFolderId: response}),
               projectActions.folderisCreated(false),
              ]).pipe(
                concatMap(action => of(action).pipe(delay(600))) // Gọi từng API một cách tuần tự
              );
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'getFolderRootIds' })],
      );
    }),
  );
};

const CreateLabel$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(projectActions.CreateLabel.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, bodyData, files, companyId, parentId} = action.payload;
      return concat(
        [startLoading({ key: 'CreateLabel' })],
        ProjectService.Post.CreateLabel(projectId, bodyData, {}).pipe(
          switchMap(result => {
            const labelid = result.id;
            if (files && companyId && labelid) {
              return [issueActions.uploadFileForFolder({ companyId, labelid, files, parentId})];
            }
            return [];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'CreateLabel' })],
      );
    }),
  );
};

const getLabel$ : RootEpic =  action$ => {
  return action$.pipe(
    filter(projectActions.getLabel.match),
    switchMap(action => {
      const { id, isbiding } = action.payload;
      return concat(
        [startLoading({ key: 'getLabel' })],
        ProjectService.Get.getLabel(id).pipe(
          switchMap(response => {
            if (isbiding) {
              return [projectActions.setLabel(response.documentChildren)];
            }
            return [projectActions.setLabel(response.labelChildren)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [projectActions.setLabel([])];
          }),
        ),
        [stopLoading({ key: 'getLabel' })],
      );
    }),
  );
};

const updateLabel$ : RootEpic =  action$ => {
  return action$.pipe(
    filter(projectActions.updateLabel.match),
    switchMap(action => {
      const { idLabel, inputData, parentId } = action.payload;
      return concat(
        [startLoading({ key: 'updateLabel' })],
        ProjectService.Put.updateLabel(idLabel, inputData , {}).pipe(
          switchMap(response => {
             Utils.successNotification()
             return [projectActions.getLabel({id: parentId})]
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'updateLabel' })],
      );
    }),
  );
};





const getFileRoots$: RootEpic =  action$ => {
  return action$.pipe(
    filter(projectActions.getFileRoots.match),
    switchMap(action => {
      const { rootFolderId, isEdit } = action.payload;
      return concat(
        [startLoading({ key: 'getFileRoot' })],
        ProjectService.Get.getFileRoots(rootFolderId).pipe(
          switchMap(response => {
            const isCheck = response.results.find((item: any) => item.name === 'ungluong1');
            if (isEdit) {
              return [projectActions.setListFileRootsEdit(response)]
            }
            if (response && response.results && response.results.length > 3 && isCheck) {
              return [projectActions.setListFileRoots(response)];
            } else {
              return [projectActions.folderisCreated(true)]
            }
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [projectActions.setListFileRoots([])];
          }),
        ),
        [stopLoading({ key: 'getFileRoot' })],
      );
    }),
  );
};


const updateProjectRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(projectActions.updateProjectRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId, data, companyId } = action.payload;
      const search = state.project.queryParams;
      return concat(
        [startLoading({ key: SavingProject })],
        ProjectService.Put.updateProject(projectId, data).pipe(
          switchMap(result => {
            return ProjectService.Get.getProjectsByCompanyId(companyId, { search }).pipe(
              mergeMap(projects => {
                const updatedProject = projects.find((x: any) => x.id === projectId);
                Utils.successNotification();
                return [
                  projectActions.setSelectedProject(updatedProject),
                  projectActions.setProjectList(projects),
                ];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [];
              }),
            );
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: SavingProject })],
      );
    }),
  );
};

const getStatusListRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.getStatusListRequest.match),
    switchMap(action => {
      const { queryParams } = action.payload;
      return concat(
        [startLoading({ key: GettingProjectStatusList })],
        ProjectService.Get.getProjectStatusList({ search: queryParams }).pipe(
          map(statuses => projectActions.setProjectStatuses(statuses)),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [projectActions.setProjectStatuses(undefined)];
          }),
        ),
        [stopLoading({ key: GettingProjectStatusList })],
      );
    }),
  );
};

const getProjectMembersRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.getProjectMembersRequest.match),
    switchMap(action => {
      const { projectId, queryParams } = action.payload;
      return concat(
        [startLoading({ key: GettingProjectMembers })],
        ProjectService.Get.getProjectMembers(projectId, { search: queryParams }).pipe(
          map(members => {
            return projectActions.setProjectMembers(members);
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [projectActions.setProjectMembers(undefined)];
          }),
        ),
        [stopLoading({ key: GettingProjectMembers })],
      );
    }),
  );
};

const getProjectRolesRequest$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.getProjectRolesRequest.match),
    switchMap(action => {
      const { queryParams } = action.payload;
      return concat(
        [startLoading({ key: GettingProjectRolesLoadingKey })],
        ProjectService.Get.getProjectRoles({ search: queryParams }).pipe(
          mergeMap(roles => {
            return [projectActions.setProjectRoles(roles)];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [projectActions.setProjectRoles(undefined)];
          }),
        ),
        [stopLoading({ key: GettingProjectRolesLoadingKey })],
      );
    }),
  );
};

export const createManyProjectMembers$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(projectActions.createManyProjectMemberRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { members } = action.payload;
      const { selectedProject, queryParams } = state.project;
      if (!selectedProject) {
        return [];
      }
      return concat(
        [startLoading({ key: CreateManyProjectMemberLoadingKey })],
        ProjectService.Post.createManyProjectMembers(members).pipe(
          switchMap(() => {
            return ProjectService.Get.getProjectMembers(selectedProject.id, { search: queryParams }).pipe(
              mergeMap(projMembers => {
                Utils.successNotification();
                return [projectActions.setProjectMembers(projMembers), hideModal({ key: AddMemberToProjectModalName })];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [projectActions.setProjectMembers(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: CreateManyProjectMemberLoadingKey })],
      );
    }),
  );
};

export const removeProjectMemberRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(projectActions.removeProjectMemberRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { employeeId } = action.payload;
      const { selectedProject, queryParams } = state.project;
      if (!selectedProject) {
        return [];
      }
      return concat(
        [startLoading({ key: SavingProject })],
        ProjectService.Delete.removeProjectMember(selectedProject.id, employeeId).pipe(
          switchMap(() => {
            return ProjectService.Get.getProjectMembers(selectedProject.id, { search: queryParams }).pipe(
              mergeMap(projMembers => {
                Utils.successNotification('Removed successfully');
                return [projectActions.setProjectMembers(projMembers), hideModal({ key: AddMemberToProjectModalName })];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [projectActions.setProjectMembers(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: SavingProject })],
      );
    }),
  );
};

export const updateProjectMemberRequest$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(projectActions.updateProjectMemberRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { employeeId, member } = action.payload;
      const { selectedProject, queryParams } = state.project;
      if (!selectedProject) {
        return [];
      }
      return concat(
        [startLoading({ key: SavingProjectMemberLoadingKey })],
        ProjectService.Put.updateProjectMember(selectedProject.id, employeeId, member).pipe(
          switchMap(() => {
            return ProjectService.Get.getProjectMembers(selectedProject.id, { search: queryParams }).pipe(
              mergeMap(projMembers => {
                Utils.successNotification();
                return [projectActions.setProjectMembers(projMembers), hideModal({ key: EditProjectMemberModalName })];
              }),
              catchError(errors => {
                Utils.errorHandling(errors);
                return [projectActions.setProjectMembers(undefined)];
              }),
            );
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: SavingProjectMemberLoadingKey })],
      );
    }),
  );
};
const createWarehouses$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.createWarehousesRequest.match),
    switchMap(action => {
      const { projectId, data } = action.payload;

      return concat(
        [startLoading({ key: 'createWarehouses' })],
        ProjectService.Post.createWarehouse(data).pipe(
          switchMap((response) => {
            // Tạo từng request liên kết kho theo thứ tự
            return from(data).pipe(
              concatMap((warehouse) => {
                // Tạo object request cho từng kho
                const request = {
                  projectId: projectId,
                  warehouseCode: warehouse.ma_Kho,
                  warehouseId: -1,
                  type: 0,
                  status: 0,
                  note: "",
                  createTime: new Date()
                };

                // Gửi request tạo liên kết kho
                return ProjectService.Post.createProjectWarehouse(request).pipe(
                  map((result) => projectActions.setProjectWarehouseResponse(result))
                );
              })
            );
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return of(stopLoading({ key: 'createWarehouses' }));
          }),
        ),
        [stopLoading({ key: 'createWarehouses' })],
      );
    }),
  );
};

// chưa sử dụng.
const createProjectWarehouse$: RootEpic = action$ => {
  return action$.pipe(
    filter(projectActions.createProjectWarehouseRequest.match),
    switchMap(action => {
      const data = action.payload;
      return concat(
        [startLoading({ key: 'createProjectWarehouse' })],
        ProjectService.Post.createProjectWarehouse(data).pipe(
          map(result => projectActions.setProjectWarehouseResponse(result)),
          catchError(error => {
            Utils.errorHandling(error);
            return of(stopLoading({ key: 'createProjectWarehouse' }));
          }),
        ),
        [stopLoading({ key: 'createProjectWarehouse' })],
      );
    }),
  );
};

const projectwarehouse$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(projectActions.getWarehousesRequest.match),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { projectId } = action.payload;
      return concat(
        [startLoading({ key: 'getWarehouses' })],
        ProjectService.Get.getProjectWarehouses(projectId).pipe(
          mergeMap(repon => {
            return [projectActions.setprojectwarehouse(repon)];
          }),
          catchError(errors => {
            Utils.errorHandling(errors);
            return [];
          }),
        ),
        [stopLoading({ key: 'getWarehouses' })],
      );
    }),
  );
};
export const projectEpics = [
  getProjects$,
  getProjectById$,
  getProjectsByCompanyId$,
  getEmployeesByCompanyId$,
  getRolesByCompanyId$,
  createProject$,
  updateProjectRequest$,
  getStatusListRequest$,
  getProjectMembersRequest$,
  getProjectRolesRequest$,
  createManyProjectMembers$,
  removeProjectMemberRequest$,
  updateProjectMemberRequest$,
  createFolderRootProject$,
  getFolderRootId$,
  CreateLabel$,
  getLabel$,
  getFileRoots$,
  updateLabel$,
  createWarehouses$,
  createProjectWarehouse$,
  projectwarehouse$,
];
